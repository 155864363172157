import { SelectChangeEvent } from "@mui/material";
import { CheckboxField } from "./CheckboxInput";
import { getDropdownField } from "./DropdownInput";
import { FileInput } from "./FileInput";
import { InputField } from "../InputForm";
import { TextFieldInput } from "./TextInput";
import { InputFormData } from "../useFormDataHandler";

export interface FormInputProps {
	field: InputField
	formData: InputFormData
	setFormData: React.Dispatch<React.SetStateAction<InputFormData>>
}

export const Input = ({ field, formData, setFormData }: FormInputProps) => {

	const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setFormData((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleDropdownChange = (event: SelectChangeEvent<string>) => {
		const { name, value } = event.target;
		setFormData((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, checked } = event.target;
		setFormData((prevState) => ({
			...prevState,
			[name]: checked,
		}));
	};

	switch (field.type) {
		case 'LARGE_TEXT':
		case 'TEXT': return (
			<TextFieldInput
				label={field.label}
				name={field.name}
				formData={formData}
				handler={handleTextChange}
				large={field.type === 'LARGE_TEXT'}
				showPasswordHelper={field.showPasswordHelper ?? false}
				editable={field.editable}
			/>
		)

		case 'DROPDOWN':
			if (!field.possibleValues) {
				console.error('Values must be number[] or string[] for DROPDOWN input type.');
				return <></>
			}
			return getDropdownField(field.label, field.name, field.possibleValues, formData, handleDropdownChange);

		case 'CHECKBOX': return <CheckboxField
			label={field.label}
			name={field.name}
			formData={formData}
			handler={handleCheckboxChange}
			required={false}
			editable={field.editable}
			nonEditableMessage={field.nonEditableMessage}
			clickableText={field.clickableText}
			clickableTextAction={field.clickableTextAction}
		/>;

		case 'REQUIRED_CHECKBOX': return <CheckboxField
			label={field.label}
			name={field.name}
			formData={formData}
			handler={handleCheckboxChange}
			required={true}
			editable={field.editable}
			nonEditableMessage={field.nonEditableMessage}
			clickableText={field.clickableText}
			clickableTextAction={field.clickableTextAction}
		/>;

		case 'NUMBER': return (
			<TextFieldInput
				label={field.label}
				name={field.name}
				formData={formData}
				handler={handleTextChange}
				large={false}
				showPasswordHelper={field.showPasswordHelper ?? false}
			/>
		)

		case 'FILE': return (
			<FileInput
				name={field.name}
				formData={formData}
				setFormData={setFormData}
				key={field.label}
			/>
		)

		default:
			console.error('Unknown field type:', field.type);
			return <></>
	}
}