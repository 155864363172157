import { Box, CircularProgress } from "@mui/material";
import { SPACING_EXTRA_SMALL } from "../../constants";

export const CenteredLoadingSpinner = () => (
    <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ my: SPACING_EXTRA_SMALL }}
    >
        <CircularProgress />
    </Box>
)