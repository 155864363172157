import { Box, useTheme } from "@mui/material";

interface DividerProps {
    inverted?: boolean
    id?: string
}

export const Divider = ({ inverted, id }: DividerProps) => {
    const theme = useTheme();

    return (
        <Box
            id={id}
            width="100%"
            height="100px"
            sx={{
                backgroundColor: theme.palette.primary.main,
                transform: inverted ? 'rotate(180deg)' : ''
            }}
        >
            <Box
                width="100%"
                height="50px"
                sx={{
                    backgroundColor: 'white',
                    borderBottomLeftRadius: 100,
                    borderBottomRightRadius: 100
                }}
            />
        </Box>
    )
}