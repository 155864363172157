import { Button, Container, Typography } from "@mui/material";
import theme from "../../theme"
import { SPACING_NORMAL } from "../../constants";
import { Logo } from "../Logo/Logo";
import useCustomNavigate from "../../utils/useCustomNavigate";

export default function Footer() {
    const navigate = useCustomNavigate();

    return (
        <>
            <Container
                sx={{
                    borderTop: `1px solid ${theme.palette.divider}`,
                    pt: SPACING_NORMAL,
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <Logo />
            </Container>
            <Container
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Button onClick={() => navigate('/terms')}>Terms</Button>
                <Button onClick={() => navigate('/privacy')}>Privacy</Button>
                <Button onClick={() => navigate('/contact-us')}>Contact</Button>
            </Container>
            <Container
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mb: SPACING_NORMAL
                }}
            >
                <Typography variant="body2">Copyright 2023 Quizletic. All rights reserved.</Typography>
            </Container>
        </>
    )
}