import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { User, UserContext } from "../../utils/UserContext/UserContext"
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { useContext, useState } from "react";
import { ConfirmActionModal } from "../ConfirmActionModal/ConfirmActionModal";
import { DELETE_ACCOUNT } from "../../constants";
import useCustomNavigate from "../../utils/useCustomNavigate";

const formatKey = (key: string) => {
	return key
		.replace(/([A-Z])/g, ' $1')
		.replace(key.charAt(0), key.charAt(0).toUpperCase());
};

export const AccountInfo = () => {
	const navigate = useCustomNavigate();
	const { user, signOut } = useContext(UserContext);
	const [deleteModalVisible, setDeleteModalVisible] = useState(false);

	if (!user) return <></>;

	const keysToDisplay = Object.keys(user).filter(key =>
		key !== 'id'
		&& key !== 'accessToken'
		&& key !== 'wasPreviouslySubscribed'
		&& key !== 'plan'
	);

	const handleSignOut = () => {
		signOut();
		navigate('/');
	}

	const handleResetPassword = () => {
		navigate('/forgot-password');
	}

	const openDeleteModal = () => {
		setDeleteModalVisible(true);
	}

	return (
		<Box>
			<ConfirmActionModal
				visible={deleteModalVisible}
				title="Delete Account"
				subTitle="Are you sure you wish to delete your account? This action cannot be undone."
				cancelButton={{
					label: 'Cancel',
					closeModal: () => setDeleteModalVisible(false),
					color: 'primary',
				}}
				confirmButton={{
					label: 'Confirm',
					successCallback: () => { navigate('/'); signOut(); },
					color: 'error',
					submitUrl: DELETE_ACCOUNT
				}}
				requirePassword={true}
				requireEmail={false}
				requireUserToSubmit={false}
			/>
			<Typography variant="h2">Your Details</Typography>
			<TableContainer>
				<Table>
					<TableBody>
						{keysToDisplay.map(key => (
							<TableRow key={key}>
								<TableCell>{formatKey(key)}</TableCell>
								<TableCell>{user![key as keyof User]}</TableCell>
							</TableRow>
						))}
						<TableRow>
							<TableCell>Actions</TableCell>
							<TableCell>
								<Button
									variant="text"
									size="small"
									color="secondary"
									onClick={handleResetPassword}
								>
									Reset Password
								</Button>
								<br></br>
								<Button
									variant="text"
									size="small"
									color="secondary"
									onClick={handleSignOut}
								>
									Sign Out
								</Button>
								<br></br>
								<Button
									variant="text"
									size="small"
									color="error"
									onClick={openDeleteModal}
								>
									Delete Account
								</Button>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	)
}