import { Container, Grid, TextField, Typography } from "@mui/material"
import { SPACING_NORMAL, SPACING_SMALL } from "../../constants"
import { useEffect, useState } from "react";
import { BrowseOption, Quiz } from "../../utils/useBrowseManager";
import { QuizButton } from "./QuizButton";
import { BrowseButton } from "./BrowseButton";

interface BrowseFilterNodeProps {
    title: string
    items: BrowseOption[] | Quiz[]
    onFilterButtonClick: (item: BrowseOption | Quiz) => void
    isQuizNode: boolean
}

export const BrowseFilterNode = ({ title, items, onFilterButtonClick, isQuizNode }: BrowseFilterNodeProps) => {

    const [filter, setFilter] = useState<string>('');

    const filteredItems = items.filter(item =>
        filter === undefined || item.name.toLowerCase().includes(filter.toLowerCase())
    );

    useEffect(() => {
        setFilter('');
    }, [items, title])

    return (
        <>
            <Typography variant="h3" sx={{ my: SPACING_SMALL }}>{title}</Typography>

            <TextField
                fullWidth
                label="Filter"
                onChange={(e) => setFilter(e.target.value)}
                value={filter}
                size="small"
                sx={{ mb: SPACING_SMALL }}
            />

            <Grid container spacing={2}>
                {isQuizNode ?
                    filteredItems.map(item => <QuizButton quiz={item as Quiz} key={(item as Quiz).id} />) :
                    filteredItems.map(item => <BrowseButton item={item as BrowseOption} key={(item as BrowseOption).id} onFilterButtonClick={onFilterButtonClick} />)
                }

                {filteredItems.length === 0 && (
                    <Container sx={{ my: SPACING_NORMAL }}>
                        <Typography textAlign="center">
                            No content found! Click 'Create Quiz' above and it will appear here.
                        </Typography>
                        <Typography textAlign="center" fontSize="5em">
                            🧐
                        </Typography>
                    </Container>
                )}
            </Grid>
        </>
    )
}