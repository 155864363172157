import theme from "./theme"

const apiUrl = "https://api.quizletic.com/";

export const SIGN_IN = `${apiUrl}SignIn`;
export const REFRESH_TOKEN = `${apiUrl}RefreshToken`;
export const SIGN_UP = `${apiUrl}SignUp`;
export const VERIFY_EMAIL = `${apiUrl}VerifyEmail`;
export const FORGOT_PASSWORD = `${apiUrl}ForgotPassword`;
export const RESET_PASSWORD = `${apiUrl}ResetPassword`;
export const DELETE_ACCOUNT = `${apiUrl}DeleteAccount`;
export const RESEND_VERIFICATION_CODE = `${apiUrl}ResendVerificationCode`;
export const GET_INSTITUTIONS = `${apiUrl}GetInstitutions`;
export const GET_COURSES = `${apiUrl}GetCourses`;
export const GET_MODULES = `${apiUrl}GetModules`;
export const GET_QUIZZES = `${apiUrl}GetQuizzes`;
export const GET_OWNED_QUIZZES = `${apiUrl}GetOwnedQuizzes`;
export const CREATE_QUIZ = `${apiUrl}CreateQuiz`;
export const CREATE_SAMPLE_QUIZ = `${apiUrl}CreateSampleQuiz`;
export const GET_QUIZ = `${apiUrl}GetQuiz`;
export const DELETE_QUIZ = `${apiUrl}DeleteQuiz`

export const CONTACT_US = "https://6x3y53r6eujregmrg2cjms5ah40deywn.lambda-url.eu-west-1.on.aws/";

export const QUIZ_INPUT_CHAR_LIMIT = 5000;
export const BORDER_RADIUS = 2;
export const BOX_SHADOW = 10;
export const PROGRESS_HEIGHT = "0.5em";
export const FONT_EXTRA_SMALL = "0.75em";
export const LOGO_HEIGHT = "25em";
export const SPACING_EXTRA_SMALL = theme.spacing(1);
export const SPACING_SMALL = theme.spacing(3);
export const SPACING_NORMAL = theme.spacing(5);
export const SPACING_LARGE = theme.spacing(10);
export const SPACING_EXTRA_LARGE = theme.spacing(12);