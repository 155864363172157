import { FONT_EXTRA_SMALL, RESEND_VERIFICATION_CODE, SPACING_EXTRA_SMALL, VERIFY_EMAIL } from "../../constants";
import { InputForm, InputFormSection } from "../../components/InputForm/InputForm";
import { Button, Grid } from "@mui/material";
import { ConfirmActionModal } from "../../components/ConfirmActionModal/ConfirmActionModal";
import { useState } from "react";
import useCustomNavigate from "../../utils/useCustomNavigate";
import { useLocation } from "react-router-dom";

export const EmailVerificationPage = () => {
    const navigate = useCustomNavigate();
    const location = useLocation();
    const maybeEmail = location.state?.email;

    const [modalVisible, setModalVisible] = useState(false);

    const handleVerification = (data: any, body: any) => navigate('/sign-in', { state: { email: body.email } });

    const footerActions = (
        <Grid container justifyContent='center' sx={{ mt: SPACING_EXTRA_SMALL }}>
            <Grid item>
                <Button onClick={() => setModalVisible(true)} variant="text" sx={{ fontSize: FONT_EXTRA_SMALL }}>
                    Verification code expired? Request New Code
                </Button>
            </Grid>
            <Grid item>
                <Button onClick={() => navigate('/sign-in')} sx={{ fontSize: FONT_EXTRA_SMALL }}>
                    Already verified your email? Sign In
                </Button>
            </Grid>
        </Grid>
    );

    const sections: InputFormSection[] = [{
        inputFields: [
            { label: 'Email', name: 'email', type: 'TEXT', defaultValue: maybeEmail },
            { label: 'Verification Code', name: 'verificationCode', type: 'NUMBER' }
        ]
    }];

    return (
        <>
            <ConfirmActionModal
                visible={modalVisible}
                title="Request New Code"
                subTitle="Please confirm the email you would like your code to be sent to."
                cancelButton={{
                    label: 'Cancel',
                    closeModal: () => setModalVisible(false),
                    color: 'primary',
                }}
                confirmButton={{
                    label: 'Confirm',
                    color: 'secondary',
                    submitUrl: RESEND_VERIFICATION_CODE
                }}
                requirePassword={false}
                requireEmail={true}
                requireUserToSubmit={false}
            />

            <InputForm
                title="Email Verification"
                sections={sections}
                submitLabel='Verify Email'
                submitEndpoint={VERIFY_EMAIL}
                submitResponseCallback={handleVerification}
                footerActions={footerActions}
                requireUserToSubmit={false}
            />
        </>
    )
}