import { Box, Container, Typography } from "@mui/material"
import { BORDER_RADIUS, BOX_SHADOW, FONT_EXTRA_SMALL, SPACING_EXTRA_SMALL, SPACING_NORMAL, SPACING_SMALL } from "../../constants"
import { ReactNode } from "react"

interface InputFormContainerProps {
    title: string
    subTitle?: string
    children: ReactNode
}

export const InputFormContainer = ({ title, subTitle, children }: InputFormContainerProps) => {
    return (
        <Container maxWidth="xs" sx={{ my: SPACING_NORMAL }}>
            <Box sx={{
                boxShadow: BOX_SHADOW,
                px: SPACING_SMALL,
                pb: SPACING_SMALL,
                pt: SPACING_SMALL,
                borderRadius: BORDER_RADIUS
            }}>
                <Typography variant="h2" textAlign="center">{title}</Typography>
                <Typography textAlign="center" sx={{ mt: SPACING_EXTRA_SMALL, mb: SPACING_SMALL, fontSize: FONT_EXTRA_SMALL }}>
                    {subTitle}
                </Typography>

                {children}
            </Box>
        </Container >
    )
}