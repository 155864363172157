import { useState } from "react";
import { InputFormSection } from "./InputForm";

export const useFormSectionHandler = (sections: InputFormSection[], submitLabel: string) => {
	const [currentSectionIndex, setCurrentSectionIndex] = useState<number>(0);
	const section = sections[currentSectionIndex];

	const hasFinishedMultiPartForm = sections.length > 1 && currentSectionIndex === sections.length;
	const subTitle = hasFinishedMultiPartForm ? `Review your input, then click ${submitLabel}!` : section.label;

	const nextSection = () => {
		setCurrentSectionIndex(currIndex => currIndex + 1);
	}

	const previousSection = () => {
		setCurrentSectionIndex(currIndex => currIndex - 1);
	}

	return {
		section, currentSectionIndex, nextSection, previousSection,
		hasFinishedMultiPartForm, subTitle
	};
};