import { useState } from "react";
import { BrowseFilterNode } from "../../components/BrowseFilterNode/BrowseFilterNode";
import { BrowseOption, QUIZ_INDEX, Quiz, useBrowseManager } from "../../utils/useBrowseManager";
import { Breadcrumbs } from "./Breadcrumbs";
import { CenteredLoadingSpinner } from "../../components/CentredLoadingSpinner/CentredLoadingSpinner";

export const BrowseTree = () => {
    const [breadcrumbs, setBreadcrumbs] = useState<string[]>([]);
    const { browseInputs, isNextBrowseInputLoading, onBrowseInputChange } = useBrowseManager();

    const addBreadcrumb = (name: string) => {
        const newBreadcrumbs = [...breadcrumbs];
        newBreadcrumbs.push(name);
        setBreadcrumbs(newBreadcrumbs);
    }

    const onBreadcrumbClick = (index: number) => {
        if (index === -1) {
            setBreadcrumbs([]);
            onBrowseInputChange(0, undefined);
            return;
        }

        const newBreadcrumbs = [...breadcrumbs];
        newBreadcrumbs.splice(index + 1);
        setBreadcrumbs(newBreadcrumbs);
        onBrowseInputChange(index, browseInputs[index].selectedValueId)
    }

    const browseInputIndex = browseInputs.length - 1;
    const currentBrowseInput = browseInputs[browseInputs.length - 1];

    return (
        <>
            <Breadcrumbs breadcrumbs={breadcrumbs} onBreadcrumbClick={onBreadcrumbClick} />

            {isNextBrowseInputLoading ? (
                <CenteredLoadingSpinner />
            ) : (
                <BrowseFilterNode
                    title={currentBrowseInput.name}
                    items={currentBrowseInput.possibleValues}
                    onFilterButtonClick={(selectedOption: BrowseOption | Quiz) => {
                        onBrowseInputChange(browseInputIndex, selectedOption.id);
                        addBreadcrumb(selectedOption.name);
                    }}
                    isQuizNode={browseInputIndex === QUIZ_INDEX}
                />
            )}
        </>
    );
};