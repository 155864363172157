import { QUIZ_INPUT_CHAR_LIMIT } from "../../constants";

const getStandardStringRuleDescription = (str: string) => `Please ensure ${str} is at least two characters long.`;
const EMAIL_RULE_STR = "Please provide a valid email.";
const PASSWORD_RULE_STR = "Please enter a password that is 8+ characters long and includes at least 1 digit, symbol, lowercase and uppercase character.";
const REPEAT_PASSWORD_RULE_STR = "Please ensure both passwords match.";
const DATE_OF_BIRTH_RULE_STR = "You must be over 18 years old to register for this site.";
const GENDER_RULE_STR = "Please select a valid gender.";
const VERIFICATION_RULE_STR = "Please enter a valid verification code.";

const NUM_QUESTIONS_RULE_STR = "Please select a valid number of questions.";
const QUESTION_DIFFICULTY_RULE_STR = "Please select a question difficulty.";
const ANS_PER_QUESTION_RULE_STR = "Please select a number of answers per question.";
const CHECKBOX_RULE_STR = "Please check your checkbox inputs.";
const SHOW_RESULTS_RULE_STR = "Please select an option for when to show results.";
const QUIZ_INPUT_RULE_STR = `Please ensure the quiz input content is less than ${QUIZ_INPUT_CHAR_LIMIT} characters long.`;

const isValidStandardString = (str: string) => str.length >= 2;
export const isValidEmail = (str: string) => str.length >= 5 && str.includes('@') && str.includes('.');
export const isValidPassword = (str: string) => {
    const passwordRegExp = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?\u00A3])[A-Za-z\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?\u00A3]{8,50}$/u;
    return passwordRegExp.test(str);
};
const isValidRepeatPassword = (str1: string, str2: string) => str1 === str2;
const isValidDateOfBirth = (str: string) => {
    if (str.length === 0) return false;

    const currentDate = new Date();
    const enteredDate = new Date(str);
    if (enteredDate > currentDate) return false;

    const ageDifference = currentDate.getTime() - enteredDate.getTime();
    const ageInYears = Math.floor(ageDifference / (1000 * 60 * 60 * 24 * 365));
    return ageInYears >= 18;
}
const isValidGender = (input: number | string | File | boolean) => typeof input === 'string' && (input as string).length > 0;
const isValidVerificationCode = (code: string) => code.length === 6;
const isValidNumberOfQuestions = (input: number | string | File | boolean) => typeof input === 'number';
const isValidQuestionDifficulty = (input: number | string | File | boolean) => typeof input === 'string' && (input as string).length > 0;
const isValidAnswersPerQuestion = (input: number | string | File | boolean) => typeof input === 'number';
const isValidCheckBox = (input: number | string | File | boolean) => typeof input === 'boolean';
const isValidShowResults = (input: number | string | File | boolean) => typeof input === 'string' && (input as string).length > 0;
const isValidQuizInput = (input: number | string | File | boolean) => typeof input === 'string' && (input as string).length <= QUIZ_INPUT_CHAR_LIMIT;

export const isValidFormData = (formData: any) => {
    if (formData.firstName !== undefined && !isValidStandardString(formData.firstName as string)) return getStandardStringRuleDescription("first name");
    if (formData.lastName !== undefined && !isValidStandardString(formData.lastName as string)) return getStandardStringRuleDescription("last name");
    if (formData.email !== undefined && !isValidEmail(formData.email as string)) return EMAIL_RULE_STR;
    if (formData.password !== undefined && !isValidPassword(formData.password as string)) return PASSWORD_RULE_STR;
    if (formData.confirmPassword !== undefined && !isValidRepeatPassword(formData.password as string, formData.confirmPassword as string)) return REPEAT_PASSWORD_RULE_STR;
    if (formData.newPassword !== undefined && !isValidPassword(formData.newPassword as string)) return PASSWORD_RULE_STR;
    if (formData.dateOfBirth !== undefined && !isValidDateOfBirth(formData.dateOfBirth as string)) return DATE_OF_BIRTH_RULE_STR;
    if (formData.gender !== undefined && !isValidGender(formData.gender)) return GENDER_RULE_STR;
    if (formData.verificationCode !== undefined && !isValidVerificationCode(formData.verificationCode as string)) return VERIFICATION_RULE_STR;
    if (formData.quizTitle !== undefined && !isValidStandardString(formData.quizTitle as string)) return getStandardStringRuleDescription("quiz title");
    if (formData.numQuestions !== undefined && !isValidNumberOfQuestions(formData.numQuestions)) return NUM_QUESTIONS_RULE_STR;
    if (formData.difficulty !== undefined && !isValidQuestionDifficulty(formData.difficulty)) return QUESTION_DIFFICULTY_RULE_STR;
    if (formData.numAnswers !== undefined && !isValidAnswersPerQuestion(formData.numAnswers)) return ANS_PER_QUESTION_RULE_STR;
    if (formData.showResults !== undefined && !isValidShowResults(formData.showResults)) return SHOW_RESULTS_RULE_STR;
    if (formData.generateExplanations !== undefined && !isValidCheckBox(formData.generateExplanations)) return CHECKBOX_RULE_STR;
    if (formData.recordResults !== undefined && !isValidCheckBox(formData.recordResults)) return CHECKBOX_RULE_STR;
    if (formData.recordAnswers !== undefined && !isValidCheckBox(formData.recordAnswers)) return CHECKBOX_RULE_STR;
    if (formData.quizInputText !== undefined && !isValidQuizInput(formData.quizInputText)) return QUIZ_INPUT_RULE_STR;
    return true;
}