import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { InputFormData } from "../useFormDataHandler";

export const getDropdownField = (label: string, name: string, values: ({ label: string, value: string | number } | string)[], formData: InputFormData, handler: (event: SelectChangeEvent<string>) => void) => {
	return (
		<FormControl fullWidth margin="normal" required key={name}>
			<InputLabel id={`${name}-select-label`}>{label}</InputLabel>
			<Select
				labelId={`${name}-select-label`}
				id={`${name}-select`}
				value={formData[name] as string ?? ''}
				label={label}
				onChange={handler}
				name={name}
			>
				{values.map((value, index) =>
					<MenuItem value={typeof value === 'object' ? value.value : value} key={index}>
						{typeof value === 'object' ? value.label : value}
					</MenuItem>
				)}
			</Select>
		</FormControl>
	)
}