import { Button, Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import { SPACING_SMALL } from "../../constants";

interface BreadcrumbsProps {
    breadcrumbs: string[]
    onBreadcrumbClick: (index: number) => void
}

export const Breadcrumbs = ({ breadcrumbs, onBreadcrumbClick }: BreadcrumbsProps) => {

    return (
        <MuiBreadcrumbs maxItems={3} aria-label="breadcrumb" sx={{ mt: SPACING_SMALL }}>
            {breadcrumbs.length > 0 && (
                <Button size="large" onClick={() => onBreadcrumbClick(-1)}>
                    Back To Start
                </Button>
            )}

            {breadcrumbs.map((breadcrumb, index) => (
                <Button key={index} size="large" onClick={() => onBreadcrumbClick(index)}>
                    {breadcrumb}
                </Button>
            ))}
        </MuiBreadcrumbs>
    )
}