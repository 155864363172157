import { NavigateOptions, To, useNavigate } from 'react-router-dom';
const useCustomNavigate = () => {
	const navigate = useNavigate();

	const customNavigate = (to: To, options?: NavigateOptions): void => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		navigate(to, options);
	};

	return customNavigate;
};

export default useCustomNavigate;
