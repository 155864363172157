import { IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { useContext, useState } from "react";
import { ConfirmActionModal } from "../../components/ConfirmActionModal/ConfirmActionModal";
import { DELETE_QUIZ } from "../../constants";
import { Quiz } from "../../utils/useBrowseManager";
import { CacheContext } from "../../utils/CacheContext/CacheContext";

interface ActionButtonsProps {
    quiz: Quiz;
    updateQuizzes: () => void;
}

export const ActionButtons = ({ quiz, updateQuizzes }: ActionButtonsProps) => {
    const { clearCache } = useContext(CacheContext);
    const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState<boolean>(false);

    const handleDeleteSuccess = () => {
        updateQuizzes();
        clearCache();
    }

    return (
        <>
            <ConfirmActionModal
                visible={confirmDeleteModalVisible}
                title="Delete Quiz"
                subTitle="Are you sure you want to delete this quiz?"
                cancelButton={{
                    label: 'Cancel',
                    closeModal: () => setConfirmDeleteModalVisible(false),
                    color: 'primary',
                }}
                confirmButton={{
                    label: 'Confirm',
                    color: 'secondary',
                    submitUrl: DELETE_QUIZ,
                    successCallback: handleDeleteSuccess
                }}
                requirePassword={false}
                requireEmail={false}
                requireUserToSubmit={true}
                additionalBody={{
                    quizPartitionKey: quiz.educationInstitutionCourseYearModuleId,
                    quizSortKey: quiz.id
                }}
            />

            <IconButton
                onClick={() => setConfirmDeleteModalVisible(true)}
                size='medium'
                color='error'
            >
                <DeleteIcon />
            </IconButton>
        </>
    )
}