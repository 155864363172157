import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material"
import { RainConfetti } from "../RainConfetti/RainConfetti"
import { Question } from "./QuestionView"
import ReplayIcon from '@mui/icons-material/Replay';
import { SPACING_SMALL } from "../../constants";
import useCustomNavigate from "../../utils/useCustomNavigate";

interface CompletionViewProps {
    questions: Question[]
    answers: number[]
    showResults: boolean
    showBackToDashboardButton: boolean
    retryButtonCallback: () => void
}

export const CompletionView = ({ questions, answers, showResults, showBackToDashboardButton, retryButtonCallback }: CompletionViewProps) => {
    const navigate = useCustomNavigate();

    const theme = useTheme();
    const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));
    const retryButton = (
        <Button
            variant="contained"
            color="secondary"
            startIcon={<ReplayIcon />}
            onClick={retryButtonCallback}
            fullWidth={isScreenSmall !== undefined}
            sx={{ mt: isScreenSmall ? SPACING_SMALL : undefined }}
        >
            Retry
        </Button>
    );

    const numCorrectAnswers = questions.filter((q, index) => q.answers[answers[index]].correct).length;
    const shouldCelebrate = (numCorrectAnswers / questions.length) * 100 > 60;

    return (
        <Box sx={{ padding: SPACING_SMALL }}>
            {shouldCelebrate && <RainConfetti />}

            <Grid container justifyContent="space-between">
                <Grid item>
                    <Typography variant="h2">Quiz Completed! {shouldCelebrate && '🎉'}</Typography>
                </Grid>
                {!isScreenSmall && (
                    <Grid item>
                        {retryButton}
                    </Grid>
                )}
            </Grid>


            {showResults ? (
                <Box>
                    <Typography variant="h4">You Scored ({numCorrectAnswers}/{questions.length})</Typography>

                    {questions.map((question, qIndex) => (
                        <Box sx={{ mt: SPACING_SMALL }} key={`q-${qIndex}`}>
                            <Typography>{question.title}</Typography>

                            {question.answers.map((answer, aIndex) => {
                                if (!answer.correct && answers[qIndex] !== aIndex) return <></>;

                                return (
                                    <Typography
                                        key={`a-${aIndex}`}
                                        sx={{
                                            ml: SPACING_SMALL,
                                            fontWeight: answers[qIndex] === aIndex ? 'bold' : undefined
                                        }}>
                                        {answer.correct ? '✔ ' : '❌ '}
                                        {answer.title}
                                    </Typography>
                                )
                            })}
                        </Box>
                    ))}
                </Box>
            ) : (
                <Typography>
                    You have completed all of the questions in this quiz. Great work!
                </Typography>
            )}

            {isScreenSmall && retryButton}

            {showBackToDashboardButton && (
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => navigate('/dashboard')}
                    fullWidth={isScreenSmall !== undefined}
                    sx={{ mt: SPACING_SMALL }}
                >
                    Back to Dashboard
                </Button>
            )}
        </Box >
    )
}