import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { Logo } from '../Logo/Logo';
import { LeftNav } from './LeftNav';
import { RightNav } from './RightNav';
import { PopUpBar } from '../PopUpBar/PopUpBar';
import { PopUp } from '../../utils/PopUpContext/PopUpContext';
import { Hamburger } from './Hamburger';
import { Box } from '@mui/material';
import { DeviceTypeContext } from '../../utils/DeviceTypeContext/DeviceTypeContext';
import { useContext } from 'react';
import theme from '../../theme';
import { SPACING_EXTRA_SMALL } from '../../constants';

interface NavigationBarProps {
    popUp?: PopUp
}

export const NavigationBar = ({ popUp }: NavigationBarProps) => {
    const { deviceType } = useContext(DeviceTypeContext);
    const isMobile = deviceType === 'MOBILE';

    return (
        <AppBar
            position="static"
            sx={{ boxShadow: "none" }}
            color="transparent"
        >
            <Container
                sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}
            >
                <Toolbar disableGutters sx={{ display: 'flex', alignItems: 'center' }}>
                    <Logo sx={{ display: 'flex', alignItems: 'center', mr: SPACING_EXTRA_SMALL }} />
                    {isMobile ? (
                        <>
                            <Box sx={{ flexGrow: 1 }} />
                            <Hamburger />
                        </>
                    ) : (
                        <>
                            <LeftNav />
                            <RightNav />
                        </>
                    )}
                </Toolbar>
            </Container>

            <PopUpBar popUp={popUp} />
        </AppBar >
    );
}