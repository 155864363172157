import { createContext, useEffect, useState } from 'react';

export type DeviceType = 'DESKTOP' | 'TABLET' | 'MOBILE';

interface IDeviceTypeContext {
	deviceType: DeviceType;
}

export const DeviceTypeContext = createContext<IDeviceTypeContext>({
	deviceType: 'DESKTOP',
});

export const DeviceTypeProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [deviceType, setDeviceType] = useState<DeviceType>('DESKTOP');

	useEffect(() => {
		const handleResize = () => {
			const width = window.innerWidth;

			if (width >= 1200) {
				setDeviceType('DESKTOP');
			} else if (width >= 768) {
				setDeviceType('TABLET');
			} else {
				setDeviceType('MOBILE');
			}
		};

		handleResize();
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<DeviceTypeContext.Provider value={{ deviceType }}>
			{children}
		</DeviceTypeContext.Provider>
	);
};
