import { LinearProgress, Typography } from "@mui/material"
import { FONT_EXTRA_SMALL, PROGRESS_HEIGHT, SPACING_EXTRA_SMALL } from "../../constants"
import { InputFormSection } from "./InputForm"

interface InputFormHeaderProps {
    sections: InputFormSection[]
    currentSectionIndex: number
    title: string
    subTitle?: string
}

export const InputFormHeader = ({ sections, currentSectionIndex, title, subTitle }: InputFormHeaderProps) => {
    return (
        <>
            {sections.length > 1 &&
                <LinearProgress
                    sx={{ height: PROGRESS_HEIGHT, mb: SPACING_EXTRA_SMALL }}
                    variant="determinate"
                    color="secondary"
                    value={(currentSectionIndex / sections.length) * 100} />
            }

            <Typography variant="h2" textAlign="center">{title}</Typography>
            <Typography textAlign="center" sx={{ mt: SPACING_EXTRA_SMALL, mb: SPACING_EXTRA_SMALL, fontSize: FONT_EXTRA_SMALL }}>{subTitle}</Typography>
        </>
    )
}