import { useContext, useState } from "react";
import { PopUpContext } from "../../../utils/PopUpContext/PopUpContext";
import { InputFormData } from "../useFormDataHandler";
import { pdfjs } from 'react-pdf';
import { Box, TextField, Typography } from "@mui/material";
import { QUIZ_INPUT_CHAR_LIMIT, SPACING_EXTRA_SMALL } from "../../../constants";
import LightbulbIcon from '@mui/icons-material/Lightbulb';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const getPdfFromBuffer = async (buffer: ArrayBuffer) => {
	const arrayBuffer = new Uint8Array(buffer);
	const loadingTask = pdfjs.getDocument(arrayBuffer);
	const pdf = await loadingTask.promise;
	return pdf;
};

const extractTextFromPdf = async (pdf: any) => {
	let text = '';
	const numPages = pdf.numPages;

	for (let i = 1; i <= numPages; i++) {
		const page = await pdf.getPage(i);
		const content = await page.getTextContent();
		const pageText = content.items.map((item: any) => item.str).join(' ');
		text += pageText + '\n';
	}

	return text.substring(0, text.length - 1);
};

interface FileInputProps {
	name: string;
	formData: InputFormData;
	setFormData: React.Dispatch<React.SetStateAction<InputFormData>>;
}

export const FileInput = ({ name, formData, setFormData }: FileInputProps) => {
	const [fileReadInProgress, setFileReadInProgress] = useState<boolean>();

	const { setPopUp } = useContext(PopUpContext);

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { files } = event.target;
		if (!files || !files[0]) return;

		if (!files || !files[0] || files[0].type !== 'application/pdf') {
			return setPopUp({ severity: 'error', message: 'Please ensure you have selected a valid PDF file.' })
		}

		const selectedFile = files[0];
		setFileReadInProgress(true);

		const reader = new FileReader();
		reader.onload = async (e: ProgressEvent<FileReader>) => {
			if (e.target && e.target.result) {
				const buffer = e.target.result as ArrayBuffer;
				const pdf = await getPdfFromBuffer(buffer);
				const text = await extractTextFromPdf(pdf);

				setFormData((prevFormData) => ({
					...prevFormData,
					[name]: text,
					'quizTitle': selectedFile.name.replace(".pdf", "")
				}));

				setFileReadInProgress(false);
			}
		};
		reader.readAsArrayBuffer(selectedFile!);
	};

	const handleExtractedTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: event.target.value,
		}));
	}

	const inputLength = (formData[name] as string).length;
	const charCounter = `${inputLength}/${QUIZ_INPUT_CHAR_LIMIT}`;
	const helperEmoji =
		inputLength < 1000 ? '😍' :
			inputLength < 1500 ? '🙂' :
				inputLength < 2000 ? '😟' :
					inputLength < 4000 ? '🥵' : '🤬';
	const helperText = `${helperEmoji} ${charCounter}`;

	return (
		<Box>
			<TextField
				margin="normal"
				required
				fullWidth
				onChange={handleFileChange}
				name={name}
				type='file'
				inputProps={{ accept: '.pdf' }}
				disabled={fileReadInProgress}
				data-testid='file-input'
			/>

			<TextField
				margin="normal"
				value={formData[name]}
				multiline
				fullWidth
				required
				maxRows={10}
				error={(formData[name] as string).length > QUIZ_INPUT_CHAR_LIMIT}
				helperText={helperText}
				sx={{
					'& .MuiFormHelperText-root': {
						fontSize: '16px',
					},
				}}
				onChange={handleExtractedTextChange}
			/>

			{inputLength >= 2000 && (
				<Box display="flex" alignItems="center">
					<LightbulbIcon color="warning" sx={{ mr: SPACING_EXTRA_SMALL }} />
					<Typography>
						Smaller inputs result in more focused and accurate quizzes.
					</Typography>
				</Box>
			)}

		</Box >
	)

}