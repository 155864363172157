import { TextField } from "@mui/material";
import { InputFormData } from "../useFormDataHandler";

interface TextFieldProps {
	label: string
	name: string
	formData: InputFormData
	handler: (event: React.ChangeEvent<HTMLInputElement>) => void
	large: boolean
	showPasswordHelper: boolean
	editable?: boolean
}

export const TextFieldInput = ({ label, name, formData, handler, large, showPasswordHelper, editable = true }: TextFieldProps) => {

	const isPasswordField = name.toLowerCase() === 'password';
	const isConfirmPasswordField = name.toLowerCase().includes("password") && name.toLowerCase().includes("confirm");
	let helperText = undefined;

	if (isPasswordField && showPasswordHelper) {
		const specialCharsRegex = /[\^ $ *.[\]{ }() ?"!@#%&/\\,><':;|_~`=+\- ]/;
		const numberRegex = /[0-9]/;
		const inputValue = formData[name] as string;

		if (inputValue.length === 0) {
			// do nothing
		} else if (inputValue.toLowerCase() === inputValue) {
			helperText = "Password must contain 1 uppercase character.";
		} else if (inputValue.toUpperCase() === inputValue) {
			helperText = "Password must contain 1 lowercase character.";
		} else if (!specialCharsRegex.test(inputValue)) {
			helperText = "Password must contain 1 special character.";
		} else if (!numberRegex.test(inputValue)) {
			helperText = "Password must contain 1 number.";
		} else if (inputValue.length < 8) {
			helperText = "Password must be at least 8 characters long.";
		}
	} else if (isConfirmPasswordField && showPasswordHelper) {
		const password = formData["password"] as string;
		const confirmPassword = formData[name] as string;

		if (password !== confirmPassword && confirmPassword.length > 0) helperText = "Passwords must match!"
	}

	return (
		<TextField
			margin="normal"
			required
			fullWidth
			key={name}
			onChange={handler}
			name={name}
			value={formData[name] ?? ''}
			label={label}
			autoComplete={name}
			type={
				name.toLowerCase().includes('password') ? 'password' :
					name.toLowerCase().includes('date') ? 'date' :
						name.toLowerCase().includes('minutes') ? 'number' : undefined
			}
			InputLabelProps={name.toLowerCase().includes('date') ? { shrink: true, required: true } : undefined}
			inputProps={name.toLowerCase().includes('minutes') ? { min: 0, max: 120 } : undefined}
			multiline={large}
			rows={large ? 10 : 0}
			helperText={helperText}
			error={helperText !== undefined}
			disabled={!editable}
		/>
	)
}