import { Button, Checkbox, FormControlLabel, Typography } from "@mui/material"
import { InputFormData } from "../useFormDataHandler";
import { useContext } from "react";
import { PopUpContext } from "../../../utils/PopUpContext/PopUpContext";
import { SPACING_EXTRA_SMALL } from "../../../constants";

interface CheckboxFieldProps {
	label: string,
	name: string,
	formData: InputFormData,
	handler: (event: React.ChangeEvent<HTMLInputElement>) => void,
	required: boolean,
	clickableText?: string,
	clickableTextAction?: () => void,
	editable?: boolean,
	nonEditableMessage?: string
}

export const CheckboxField = ({
	label,
	name,
	formData,
	handler,
	required = false,
	clickableText,
	clickableTextAction,
	editable = true,
	nonEditableMessage
}: CheckboxFieldProps) => {
	const { setPopUp } = useContext(PopUpContext);
	const onChange = editable ? handler :
		nonEditableMessage ? () => setPopUp({ severity: 'error', message: nonEditableMessage }) :
			undefined

	let labelToRender = <Typography>{label}</Typography>;

	if (clickableText) {
		const parts = label.split(clickableText);
		const beforeClickableText = parts[0];
		const afterClickableText = parts[1];

		labelToRender = (
			<div style={{ display: 'flex', alignItems: 'center' }}>
				{beforeClickableText}
				<Button variant="text" sx={{ ml: SPACING_EXTRA_SMALL, padding: 0 }} onClick={clickableTextAction}>
					{clickableText}
				</Button >
				{afterClickableText}
			</div>
		);
	}

	return (
		<FormControlLabel
			control={
				<Checkbox
					checked={Boolean(formData[name])}
					onChange={onChange}
					name={name}
					color="secondary"
					required={required}
				/>
			}
			label={labelToRender}
			key={name}
		/>
	)
}