import { Box, Container } from "@mui/material"
import { Input } from "./InputFields/Input";
import { useFormDataHandler } from "./useFormDataHandler";
import { BORDER_RADIUS, BOX_SHADOW, SPACING_NORMAL, SPACING_SMALL } from "../../constants";
import { useFormSectionHandler } from "./useFormSectionHandler";
import { ReviewInput } from "./ReviewInput";
import { InputFormHeader } from "./InputFormHeader";
import { InputFormFooter } from "./InputFormFooter";
import { TipsAndTricks } from "./TipsAndTricks";

export interface InputField {
    label: string,
    name: string,
    type: 'TEXT' | 'LARGE_TEXT' | 'DROPDOWN' | 'CHECKBOX' | 'REQUIRED_CHECKBOX' | 'NUMBER' | 'FILE',
    possibleValues?: ({ label: string, value: string | number } | string)[],
    clickableText?: string,
    clickableTextAction?: () => void
    editable?: boolean
    nonEditableMessage?: string
    defaultValue?: any
    showPasswordHelper?: boolean
}

export interface InputFormSection {
    label?: string,
    inputFields: InputField[]
}

interface InputFormProps {
    title: string;
    large?: boolean;
    sections: InputFormSection[];
    submitLabel: string;
    submitEndpoint: string;
    requireUserToSubmit: boolean
    submitResponseCallback?: (data: any, body?: any) => void;
    footerActions?: JSX.Element;
    session?: string
}

export const InputForm = ({
    large,
    title,
    sections,
    submitLabel,
    submitEndpoint,
    submitResponseCallback,
    footerActions,
    requireUserToSubmit,
    session
}: InputFormProps) => {
    const { formData, setFormData, allInputFields, isFormDataComplete } = useFormDataHandler(sections);
    const { section, currentSectionIndex, nextSection, previousSection, hasFinishedMultiPartForm, subTitle } = useFormSectionHandler(sections, submitLabel);

    const footer = (
        <InputFormFooter
            {...{
                sections,
                currentSectionIndex,
                hasFinishedMultiPartForm,
                submitLabel,
                submitEndpoint,
                requireUserToSubmit,
                formData,
                isFormDataComplete,
                footerActions,
                submitResponseCallback,
                previousSection,
                nextSection,
                session
            }}
        />
    );

    return (
        <Container maxWidth={large ? "lg" : "xs"} sx={{ my: SPACING_NORMAL }}>
            <Box sx={{
                boxShadow: BOX_SHADOW,
                px: SPACING_SMALL,
                pb: SPACING_SMALL,
                pt: sections.length > 1 ? 0 : SPACING_SMALL,
                borderRadius: BORDER_RADIUS
            }}>
                {(subTitle === "Tips and Tricks") ? (
                    <TipsAndTricks footer={footer} />
                ) : (
                    <>
                        <InputFormHeader
                            sections={sections}
                            currentSectionIndex={currentSectionIndex}
                            title={title}
                            subTitle={subTitle}
                        />

                        {section ? section.inputFields.map(field =>
                            <Input
                                field={field}
                                formData={formData}
                                setFormData={setFormData}
                                key={field.name}
                            />
                        ) : (
                            <ReviewInput
                                fieldsToReview={allInputFields}
                                formData={formData}
                            />
                        )}

                        {footer}
                    </>
                )}
            </Box>
            {footerActions}
        </Container >
    )
}