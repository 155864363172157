import { Box, Button, Grid, Modal, TextField, Typography } from "@mui/material"
import { useContext, useState } from "react";
import { BORDER_RADIUS, SPACING_EXTRA_SMALL, SPACING_SMALL } from "../../constants";
import { AjaxButton } from "../AjaxButton/AjaxButton";
import { UserContext } from "../../utils/UserContext/UserContext";

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 300,
	bgcolor: 'white',
	borderRadius: BORDER_RADIUS,
	p: SPACING_SMALL
};

interface ButtonProps {
	label: string
	color: 'error' | 'primary' | 'secondary'
}

interface ConfirmButtonProps extends ButtonProps {
	submitUrl: string
	successCallback?: (data: any) => void
}

interface CancelButtonProps extends ButtonProps {
	closeModal: () => void
}

interface ConfirmActionModalProps {
	visible: boolean
	title: string
	subTitle?: string
	cancelButton: CancelButtonProps
	confirmButton: ConfirmButtonProps
	requirePassword: boolean
	requireEmail: boolean
	requireUserToSubmit: boolean
	additionalBody?: any
}

export const ConfirmActionModal = ({ visible, title, subTitle, cancelButton, confirmButton, requirePassword, requireEmail, requireUserToSubmit, additionalBody }: ConfirmActionModalProps) => {
	const { user } = useContext(UserContext);

	const [password, setPassword] = useState<string>();
	const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value)
	};

	const [email, setEmail] = useState(user?.email);
	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value)
	};

	let submitBody: any = {};
	if (additionalBody) submitBody = additionalBody;
	if (password) submitBody.password = password;
	if (email) submitBody.email = email;

	if (Object.keys(submitBody).length === 0) {
		submitBody = undefined;
	}

	return (
		<Modal
			open={visible}
			onClose={cancelButton.closeModal}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<Box sx={{ mb: (requirePassword || requireEmail) ? 0 : SPACING_SMALL }}>
					<Typography id="modal-modal-title" variant="h2" component="h2">
						{title}
					</Typography>
					<Typography id="modal-modal-description" sx={{ mt: SPACING_EXTRA_SMALL }}>
						{subTitle}
					</Typography>
				</Box>

				{requirePassword && (
					<TextField
						required
						fullWidth
						onChange={handlePasswordChange}
						label="Password"
						type="password"
						sx={{ my: SPACING_SMALL }}
					/>
				)}

				{requireEmail && (
					<TextField
						required
						fullWidth
						onChange={handleEmailChange}
						label="Email"
						sx={{ my: SPACING_SMALL }}
					/>
				)}

				<Grid container justifyContent="space-between">
					<Grid item>
						<Button
							variant="outlined"
							onClick={cancelButton.closeModal}
							color={cancelButton.color}
						>
							{cancelButton.label}
						</Button>
					</Grid>

					<Grid item>
						<AjaxButton
							label={confirmButton.label}
							submitUrl={confirmButton.submitUrl}
							successCallback={confirmButton.successCallback}
							body={submitBody}
							requireUserToSubmit={requireUserToSubmit}
							muiButtonProps={{
								variant: "contained",
								color: confirmButton.color,
								disabled:
									(requirePassword && (password === undefined || password.length === 0))
									|| (requireEmail && (email === undefined || email.length === 0))
							}}
						/>
					</Grid>
				</Grid>
			</Box>
		</Modal>
	)
}