import { useContext } from 'react';
import useCustomNavigate from '../../utils/useCustomNavigate';
import { useLocation } from 'react-router-dom';
import { InputForm, InputFormSection } from '../../components/InputForm/InputForm';
import { FONT_EXTRA_SMALL, SIGN_UP, SPACING_EXTRA_SMALL } from '../../constants';
import { PopUpContext } from '../../utils/PopUpContext/PopUpContext';
import { Button, Grid } from '@mui/material';

export const SignUpPage = () => {
    const navigate = useCustomNavigate();
    const { setPopUp } = useContext(PopUpContext);

    const location = useLocation();
    const maybeEmail = location.state?.email;

    const handleSignUp = (data: any, body: any) => {
        setPopUp({ message: 'Check your email for your verification code.', severity: 'success' })
        navigate('/email-verification', { state: { email: body.email } });
    }

    const sections: InputFormSection[] = [
        {
            label: 'After clicking sign up, we will send you and email to verify your account. If you do not verify your account within 30 days we will automatically delete all of the information provided.',
            inputFields: [
                { label: 'Email', name: 'email', type: 'TEXT', defaultValue: maybeEmail },
                { label: 'Password', name: 'password', type: 'TEXT', showPasswordHelper: true },
                { label: 'Confirm Password', name: 'confirmPassword', type: 'TEXT', showPasswordHelper: true },
                { label: 'I accept the Terms of Service', name: 'termsOfServiceAccepted', type: 'REQUIRED_CHECKBOX', clickableText: 'Terms of Service', clickableTextAction: () => window.open('/terms') },
                { label: 'I accept the Privacy Policy', name: 'privacyPolicyAccepted', type: 'REQUIRED_CHECKBOX', clickableText: 'Privacy Policy', clickableTextAction: () => window.open('/privacy') }
            ]
        }
    ];

    const footerActions = (
        <Grid container justifyContent='center' sx={{ mt: SPACING_EXTRA_SMALL }}>
            <Grid item>
                <Button onClick={() => navigate('/email-verification')} sx={{ fontSize: FONT_EXTRA_SMALL }}>
                    Already signed up? Verify Email
                </Button>
            </Grid>
            <Grid item>
                <Button onClick={() => navigate('/sign-in')} sx={{ fontSize: FONT_EXTRA_SMALL }}>
                    Already verified your email? Sign In
                </Button>
            </Grid>
        </Grid>
    );

    return (
        <>
            <InputForm
                title='Sign Up'
                sections={sections}
                submitLabel='Sign Up'
                submitEndpoint={SIGN_UP}
                submitResponseCallback={handleSignUp}
                requireUserToSubmit={false}
                footerActions={footerActions}
            />
        </>
    )
}