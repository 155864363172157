import { Box, CircularProgress, Container, LinearProgress, Paper, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useFetchQuiz } from "./useFetchQuiz"
import { QuestionView, Question } from "./QuestionView"
import { CompletionView } from "./CompletionView"
import { PROGRESS_HEIGHT, SPACING_SMALL } from "../../constants"

export interface Quiz {
    title?: string,
    questions: Question[],
}

interface QuizViewProps {
    quizPartitionKeyValue?: string
    quizSortKeyValue?: string
    exampleQuiz?: Quiz
}

export const QuizView = ({ quizPartitionKeyValue, quizSortKeyValue, exampleQuiz }: QuizViewProps) => {
    const { quiz, isLoading } = useFetchQuiz(quizPartitionKeyValue, quizSortKeyValue, exampleQuiz);

    const [selectedAnswerIndexes, setSelectedAnswerIndexes] = useState<number[]>([]);
    const [currQuestionIndex, setCurrQuestionIndex] = useState<number>(0);

    const nextQuestion = (previousSelectedAnswerIndex: number) => {
        const newSelectedAnswerIndexes = selectedAnswerIndexes;
        newSelectedAnswerIndexes[currQuestionIndex] = previousSelectedAnswerIndex;
        setSelectedAnswerIndexes(newSelectedAnswerIndexes);
        setCurrQuestionIndex(currQuestionIndex + 1);
    }

    const backToStart = () => {
        setSelectedAnswerIndexes([]);
        setCurrQuestionIndex(0);
    }

    useEffect(() => {
        setSelectedAnswerIndexes([]);
        setCurrQuestionIndex(0);
    }, [quiz]);

    if (!quiz || !quiz.questions || isLoading) return (
        <Container maxWidth="lg">
            <Box display="flex" alignItems="center" justifyContent="center">
                <CircularProgress />
            </Box>
        </Container>
    )

    return (
        <Container maxWidth="lg">
            {quiz.title && <Typography variant="h2" sx={{ mb: SPACING_SMALL, textAlign: "center" }}>{quiz.title}</Typography>}

            <Paper elevation={3} style={{ color: 'primary.contrastText' }}>
                <LinearProgress
                    sx={{ height: PROGRESS_HEIGHT }}
                    variant="determinate"
                    color="secondary"
                    value={(currQuestionIndex / quiz?.questions.length) * 100} />

                {quiz.questions[currQuestionIndex] ? (
                    <QuestionView
                        question={quiz.questions[currQuestionIndex]}
                        showResults={true}
                        forceCorrectAnswer={false}
                        nextQuestionCallback={nextQuestion}
                    />
                ) : (
                    <CompletionView
                        questions={quiz.questions}
                        answers={selectedAnswerIndexes}
                        showResults={true}
                        showBackToDashboardButton={false}
                        retryButtonCallback={backToStart}
                    />
                )}
            </Paper>
        </Container >
    )
}