import { Box, InputLabel, TextField } from "@mui/material";
import { SPACING_EXTRA_SMALL } from "../../constants";
import { useContext, useState } from "react";
import { PopUpContext } from "../../utils/PopUpContext/PopUpContext";
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const getPdfFromBuffer = async (buffer: ArrayBuffer) => {
	const arrayBuffer = new Uint8Array(buffer);
	const loadingTask = pdfjs.getDocument(arrayBuffer);
	const pdf = await loadingTask.promise;
	return pdf;
};

const extractTextFromPdf = async (pdf: any) => {
	let text = '';
	const numPages = pdf.numPages;

	for (let i = 1; i <= numPages; i++) {
		const page = await pdf.getPage(i);
		const content = await page.getTextContent();
		const pageText = content.items.map((item: any) => item.str).join(' ');
		text += pageText + '\n';
	}

	return text.substring(0, text.length - 1);
};

interface FileInputProps {
    setQuizInputText: React.Dispatch<React.SetStateAction<string | undefined>>
    setQuizTitle: React.Dispatch<React.SetStateAction<string | undefined>>
}

export const FileInput = ({ setQuizInputText, setQuizTitle }: FileInputProps) => {
    const { setPopUp } = useContext(PopUpContext);

    const [fileReadInProgress, setFileReadInProgress] = useState<boolean>(false);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;
        if (!files || !files[0]) return;
    
        if (!files || !files[0] || files[0].type !== 'application/pdf') {
            return setPopUp({ severity: 'error', message: 'Please ensure you have selected a valid PDF file.' })
        }
    
        const selectedFile = files[0];
        setFileReadInProgress(true);
    
        const reader = new FileReader();
        reader.onload = async (e: ProgressEvent<FileReader>) => {
            if (e.target && e.target.result) {
                const buffer = e.target.result as ArrayBuffer;
                const pdf = await getPdfFromBuffer(buffer);
                const text = await extractTextFromPdf(pdf);
                setQuizInputText(text);
                setQuizTitle(selectedFile.name.replaceAll('.pdf', '').replaceAll('_', ' ').replaceAll('-', ' '));
                setFileReadInProgress(false);
            }
        };
        reader.readAsArrayBuffer(selectedFile!);
    };

    return (
        <Box sx={{my: SPACING_EXTRA_SMALL}}>
            <InputLabel sx={{mb: 0}}>
                Lecture Slides (PDF)
            </InputLabel>
            <TextField
                margin="normal"
                required
                fullWidth
                onChange={handleFileChange}
                type='file'
                inputProps={{ accept: '.pdf' }}
                disabled={fileReadInProgress}
                sx={{mt: 0}}
            />
        </Box>
    )
}