import React, { useContext, useState } from 'react';
import { Button, Container, Grid, Typography } from "@mui/material";
import { SPACING_NORMAL, SPACING_SMALL } from "../../constants";
import useCustomNavigate from '../../utils/useCustomNavigate';
import { UserContext } from '../../utils/UserContext/UserContext';
import { calculateTimeUntilNextMidnight } from '../../utils/calculateTimeUntilMidnight';
import { BrowseTree } from './BrowseTree';
import { DisclaimerModal } from '../../components/DisclaimerModal/DisclaimerModal';

export const BrowsePage = () => {
    const navigate = useCustomNavigate();
    const { user } = useContext(UserContext);

    const { hours, minutes } = calculateTimeUntilNextMidnight();

    const [browseDisclaimerAccepted, setBrowseDisclaimerAccepted] = useState<boolean>(
        Boolean(localStorage.getItem('browseDisclaimerAccepted'))
    );

    const handleBrowseDisclaimerAccepted = () => {
        localStorage.setItem('browseDisclaimerAccepted', 'true');
        setBrowseDisclaimerAccepted(true);
    }

    const disclaimer = `Quizletic is not associated with any educational institution. 
    All content on this page is generated by AI on content uploaded by our users which they have confirmed to not be copyrighted.
    We only store the generated quizzes and do not store the materials used to create them.
    There is no guarantee that AI-generated content is 100% accurate; please make use of the report buttons to flag any questions with incorrect information.`

    return (
        <Container maxWidth="md" sx={{ my: SPACING_NORMAL }}>
            <DisclaimerModal disclaimer={disclaimer} onAccept={handleBrowseDisclaimerAccepted} visible={!browseDisclaimerAccepted} />

            <Grid container spacing={2} sx={{ mb: SPACING_SMALL }}>
                <Grid item xs={12} md={10}>
                    <Typography variant="h2">Browse Quizzes</Typography>
                    <Typography>
                        Search through quizzes previously generated by our community.
                        If you can't find your university, school, or subject, go ahead and generate the first quiz!
                    </Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => navigate('/create-quiz')}
                        disabled={user !== undefined && user.remainingQuizzes <= 0}
                        fullWidth
                    >Create Quiz {user && `(${user.remainingQuizzes})`}</Button>
                    {user !== undefined && (
                        <Typography fontSize="0.8em" textAlign='center'>Resets in {hours}hr {minutes}m</Typography>
                    )}
                </Grid>
            </Grid>

            <BrowseTree />

        </Container>
    );
};
