import { Box, Container, IconButton, LinearProgress } from "@mui/material";
import { SPACING_EXTRA_SMALL } from "../../constants";
import { useContext, useEffect, useState } from "react";
import { PopUp, PopUpContext } from "../../utils/PopUpContext/PopUpContext";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';

interface PopUpBarProps {
	popUp?: PopUp;
}

export const PopUpBar = ({ popUp }: PopUpBarProps) => {
	const { setPopUp } = useContext(PopUpContext);
	const [timePassedPercentage, setTimePassedPercentage] = useState<number>(0);

	const icon = popUp ?
		popUp.severity === 'error' ? <ErrorOutlineIcon sx={{ mr: SPACING_EXTRA_SMALL }} /> :
			popUp.severity === 'success' ? <CheckCircleOutlineIcon sx={{ mr: SPACING_EXTRA_SMALL }} /> :
				<InfoIcon sx={{ mr: SPACING_EXTRA_SMALL }} /> : '';

	useEffect(() => {
		if (popUp) {
			const startTime = Date.now();
			const interval = setInterval(() => {
				const currentTime = Date.now();
				const elapsedTime = currentTime - startTime;
				const percentage = (elapsedTime / 7000) * 100;
				setTimePassedPercentage(percentage);

				if (percentage >= 105) {
					clearInterval(interval);
					setPopUp(undefined);
					setTimePassedPercentage(0);
				}
			}, 50);

			return () => {
				clearInterval(interval);
			};
		}
	}, [popUp, setPopUp]);

	if (!popUp) return <></>;

	const backgroundColor = popUp.severity === "error" ? "error.main" :
		popUp.severity === 'success' ? "success.main" :
			'info.main';
	return (
		<Container
			sx={{
				position: "relative",
				minHeight: "2.5em",
				padding: SPACING_EXTRA_SMALL,
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between",
				backgroundColor: backgroundColor,
				color:
					popUp.severity === "error"
						? "error.contrastText"
						: "success.contrastText",
			}}
		>
			<Box display="flex" alignItems="center">
				{icon}
				{popUp.message}
			</Box>
			<IconButton onClick={() => setPopUp(undefined)}>
				<CloseIcon
					sx={{
						color:
							popUp.severity === "error"
								? "error.contrastText"
								: "success.contrastText"
					}}
				/>
			</IconButton>
			<LinearProgress
				variant="determinate"
				color="secondary"
				value={timePassedPercentage}
				sx={{
					position: "absolute",
					bottom: 0,
					left: 0,
					width: "100%",
					borderRadius: 0,
				}}
			/>
		</Container>
	);
};
