import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useContext } from 'react';
import { UserContext } from '../../utils/UserContext/UserContext';
import useCustomNavigate from '../../utils/useCustomNavigate';

export const LEFT_NAV_PAGES = [
    { label: 'Home', route: '/', showIfLoggedIn: true, showIfLoggedOut: true },
    { label: 'Browse', route: '/browse', showIfLoggedIn: true, showIfLoggedOut: true },
    { label: 'Dashboard', route: '/dashboard', showIfLoggedIn: true, showIfLoggedOut: false },
];

const getPages = (isLoggedIn: boolean) => {
    return LEFT_NAV_PAGES.filter(page => isLoggedIn ? page.showIfLoggedIn : page.showIfLoggedOut);
}

export const LeftNav = () => {
    const { user } = useContext(UserContext);
    const navigate = useCustomNavigate();

    return (
        <Box sx={{ flexGrow: 1 }}>
            {getPages(Boolean(user)).map((page) =>
                <Button
                    key={page.label}
                    onClick={() => navigate(page.route)}
                    variant="text"
                    color="primary"
                >
                    {page.label}
                </Button>
            )}
        </Box>
    );
}