import { InputForm, InputFormSection } from "../../components/InputForm/InputForm";
import { RESET_PASSWORD } from "../../constants";
import useCustomNavigate from "../../utils/useCustomNavigate";

export const ResetPasswordPage = () => {
	const navigate = useCustomNavigate();

	const handleResetPassword = () => navigate('/sign-in');

	const sections: InputFormSection[] = [{
		inputFields: [
			{ label: 'Email', name: 'email', type: 'TEXT' },
			{ label: 'Verification Code', name: 'verificationCode', type: 'NUMBER' },
			{ label: 'New Password', name: 'password', type: 'TEXT', showPasswordHelper: true },
			{ label: 'Confirm New Password', name: 'confirmPassword', type: 'TEXT' }
		]
	}];

	return (
		<InputForm
			title="Reset Password"
			sections={sections}
			submitLabel='Reset Password'
			submitEndpoint={RESET_PASSWORD}
			submitResponseCallback={handleResetPassword}
			requireUserToSubmit={false}
		/>
	);
}