import { createContext } from 'react';

export interface PopUp {
	message?: string,
	severity: 'success' | 'error' | 'info'
};

interface IPopUpContext {
	setPopUp: (popUp?: PopUp) => void;
}

export const PopUpContext = createContext<IPopUpContext>({
	setPopUp: (popUp?: PopUp) => { }
});