import { Box, Button, Grid, Typography } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { SPACING_EXTRA_SMALL, SPACING_NORMAL, SPACING_SMALL } from "../../constants"
import { DeviceTypeContext } from "../../utils/DeviceTypeContext/DeviceTypeContext"
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import Confetti from 'react-confetti';

export interface Answer {
    title: string,
    correct: boolean,
    explanation?: string
}

export interface Question {
    title: string,
    answers: Answer[]
}

interface Explanation {
    isCorrect: boolean
    text: string
}

interface QuestionViewProps {
    question: Question
    showResults: boolean
    forceCorrectAnswer: boolean
    nextQuestionCallback: (selectedAnswerIndex: number) => void;
}

export const QuestionView = ({ question, showResults, forceCorrectAnswer, nextQuestionCallback }: QuestionViewProps) => {
    const [explanation, setExplanation] = useState<Explanation>();
    const { deviceType } = useContext(DeviceTypeContext);

    const [finalAnswerIndex, setFinalAnswerIndex] = useState<number>();
    const [correctAnswerIndex, setCorrectAnswerIndex] = useState<number>();
    const [incorrectAnswerIndex, setIncorrectAnswerIndex] = useState<number>();

    useEffect(() => {
        setExplanation(undefined);
        setFinalAnswerIndex(undefined);
        setCorrectAnswerIndex(undefined);
        setIncorrectAnswerIndex(undefined);
    }, [question]);

    const handleAnswerClick = (index: number) => {
        if (finalAnswerIndex !== undefined) return;

        const answer = question.answers[index];
        if (answer.correct) {
            setIncorrectAnswerIndex(undefined);
            setCorrectAnswerIndex(index);
        } else {
            setCorrectAnswerIndex(undefined);
            setIncorrectAnswerIndex(index);
            setFinalAnswerIndex(undefined);
        }

        if (showResults && answer.explanation && answer.explanation.length > 0) {
            setExplanation({ text: `${answer.explanation}${(forceCorrectAnswer && !answer.correct) ? " Try Again!" : ""}`, isCorrect: answer.correct })
        } else if (forceCorrectAnswer && !answer.correct) {
            setExplanation({ text: "Try Again!", isCorrect: answer.correct })
        }

        if (forceCorrectAnswer && !answer.correct) return;

        setFinalAnswerIndex(index);
    }

    const handleNextQuestionClick = () => {
        if (finalAnswerIndex === undefined) return;
        nextQuestionCallback(finalAnswerIndex);
    }

    return (
        <Box sx={{ padding: SPACING_SMALL }}>
            <Typography variant="h4" align="center">{question?.title}</Typography>

            <Box>
                <Grid container
                    justifyContent="center"
                    alignItems="stretch"
                    rowGap={SPACING_NORMAL}
                    columnGap={SPACING_NORMAL}
                    sx={{ my: SPACING_NORMAL }}
                >
                    {question.answers.map((answer, index) => {
                        const isCorrect = index === correctAnswerIndex;
                        const isIncorrect = index === incorrectAnswerIndex;
                        const border = (showResults && isCorrect) ? "2px solid green" : (showResults && isIncorrect) ? "2px solid red" : "2px solid #0B0B45"
                        const shouldHideUnselectedAnswer = finalAnswerIndex !== undefined && deviceType === 'MOBILE' && !isCorrect && !isIncorrect;

                        return (
                            <Grid item key={index} xs={deviceType === 'MOBILE' ? 12 : 5}
                                sx={{ display: shouldHideUnselectedAnswer ? "none" : "flex", justifyContent: "center" }}
                            >
                                <Button
                                    fullWidth
                                    sx={{
                                        height: "100px",
                                        width: "270px",
                                        border: border,
                                        paddingX: SPACING_EXTRA_SMALL
                                    }}
                                    onClick={() => handleAnswerClick(index)}
                                    disabled={finalAnswerIndex !== undefined}
                                >
                                    <Typography
                                        variant="h5"
                                        overflow="hidden"
                                        sx={{
                                            display: '-webkit-box',
                                            overflow: 'hidden',
                                            WebkitBoxOrient: 'vertical',
                                            WebkitLineClamp: 4,
                                        }}
                                    >
                                        {answer.title}
                                    </Typography>

                                    {(showResults && isCorrect) && (
                                        <>
                                            <CheckCircleIcon sx={{ fontSize: 30, zIndex: 1, position: "absolute", top: -10, right: -8, color: "green" }} />
                                            <Confetti width={260} height={90} numberOfPieces={50} confettiSource={{ x: 135, y: -10, w: 20, h: 0 }} />
                                        </>
                                    )}

                                    {(showResults && isIncorrect) && (
                                        <CancelIcon sx={{ fontSize: 30, zIndex: 1, position: "absolute", top: -10, right: -8, color: "red" }} />
                                    )}

                                </Button>
                            </Grid>
                        )
                    })}
                </Grid>

                {explanation && (
                    <Typography align="center" sx={{ my: SPACING_SMALL }}>
                        {explanation.isCorrect ? '✔ ' : '❌ '}
                        {explanation.text}
                    </Typography>
                )}

                {finalAnswerIndex !== undefined && (
                    <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={handleNextQuestionClick}
                    >
                        Next Question
                    </Button>
                )}
            </Box>
        </Box>
    )
}