import Container from "@mui/material/Container/Container"
import { UserContext } from "../../utils/UserContext/UserContext"
import { useContext } from "react"
import { AccountInfo } from "../../components/AccountInfo/AccountInfo"
import { SPACING_NORMAL } from "../../constants"
import useCustomNavigate from "../../utils/useCustomNavigate"

export const MyAccountPage = () => {
	const navigate = useCustomNavigate();
	const { user } = useContext(UserContext);
	if (!user) navigate('/');

	return (
		<Container maxWidth="md" sx={{ mt: SPACING_NORMAL }}>
			<AccountInfo />
		</Container>
	)
}