import { FORGOT_PASSWORD } from "../../constants";
import { InputForm, InputFormSection } from "../../components/InputForm/InputForm";
import useCustomNavigate from "../../utils/useCustomNavigate";

export const ForgotPasswordPage = () => {
    const navigate = useCustomNavigate();

    const handleForgotPassword = () => navigate('/reset-password');

    const sections: InputFormSection[] = [{
        label: 'If an account exists for the email you provide, we will send you an email with information on how to recover your account.',
        inputFields: [
            { label: 'Email', name: 'email', type: 'TEXT' }
        ]
    }];

    return (
        <InputForm
            title="Forgot Password"
            sections={sections}
            submitLabel='Forgot Password'
            submitEndpoint={FORGOT_PASSWORD}
            submitResponseCallback={handleForgotPassword}
            requireUserToSubmit={false}
        />
    )
}