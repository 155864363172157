import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { UserContext } from "../../utils/UserContext/UserContext";
import { useContext } from "react";
import { DeviceTypeContext } from "../../utils/DeviceTypeContext/DeviceTypeContext";
import { ActionButtons } from "./ActionButtons";
import { useQuizzesManager } from "./useQuizzesManager";
import { SPACING_NORMAL, SPACING_SMALL } from "../../constants";
import useCustomNavigate from "../../utils/useCustomNavigate";
import { calculateTimeUntilNextMidnight } from "../../utils/calculateTimeUntilMidnight";
import { Quiz } from "../../utils/useBrowseManager";

const renderCellAsTextButton = (text: string, color: 'primary' | 'secondary' | 'error', callbackFunc: () => void) => {
    return (
        <Button
            variant="text"
            color={color}
            onClick={callbackFunc}
            sx={{
                textDecoration: 'underline',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }}
        >
            <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{text}</span>
        </Button>
    )
}

export const DashboardPage = () => {
    const navigate = useCustomNavigate();
    const { user } = useContext(UserContext);
    if (!user) navigate('/');

    const { deviceType } = useContext(DeviceTypeContext);
    const { quizzes, isLoading, updateQuizzes } = useQuizzesManager();

    const viewQuiz = (quiz: Quiz) => {
        navigate('/view-quiz', {
            state: {
                quizPartitionKeyValue: quiz.educationInstitutionCourseYearModuleId,
                quizSortKeyValue: quiz.id
            }
        })
    }

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Title',
            type: 'string',
            flex: 2,
            minWidth: 100,
            renderCell: (params) =>
                renderCellAsTextButton(params.value, 'secondary', () => viewQuiz(params.row))
        },
        {
            field: 'actions',
            headerName: 'Actions',
            type: 'string',
            flex: 0,
            minWidth: 70,
            maxWidth: 70,
            sortable: false,
            renderCell: (params) =>
                <ActionButtons {...{ quiz: params.row, updateQuizzes }} />
        }
    ];

    if (deviceType !== 'MOBILE') columns.splice(1, 0, {
        field: 'timeCreated',
        headerName: 'Created At',
        type: 'string',
        flex: 1,
        minWidth: 150,
        valueFormatter: (params) => {
            const date = new Date(params.value as number);
            return date.toLocaleString();
        },
    });

    const { hours, minutes } = calculateTimeUntilNextMidnight();

    return (
        <Container maxWidth="md" sx={{ mt: SPACING_NORMAL }}>
            <Grid container justifyContent="space-between" sx={{ mb: SPACING_SMALL }}>
                <Grid item>
                    <Typography variant="h2">Your Quizzes</Typography>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => navigate('/create-quiz')}
                        disabled={user === undefined || user.remainingQuizzes <= 0}
                    >Create Quiz {user && `(${user.remainingQuizzes})`}</Button>
                    <Typography fontSize="0.8em" textAlign='center'>Resets in {hours}hr {minutes}m</Typography>
                </Grid>
            </Grid>

            {isLoading ? (
                <Typography>Loading...</Typography>
            ) : quizzes && quizzes.length > 0 ? (
                <>
                    <DataGrid
                        rows={quizzes}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 5 },
                            },
                        }}
                        pageSizeOptions={[5, 10, 25]}
                        disableRowSelectionOnClick
                        disableDensitySelector
                        disableColumnSelector
                        hideFooter={quizzes.length < 6}
                    />
                </>
            ) : (
                <Typography>You haven't created any quizzes yet!</Typography>
            )}
        </Container>
    )
}