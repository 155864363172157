import { Box, Typography } from "@mui/material"
import { SPACING_EXTRA_SMALL, SPACING_SMALL } from "../../constants"
import TextFieldsIcon from '@mui/icons-material/TextFields';
import EditNoteIcon from '@mui/icons-material/EditNote';
import ShortTextIcon from '@mui/icons-material/ShortText';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import ConstructionIcon from '@mui/icons-material/Construction';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

interface TipsAndTricksProps {
	footer: JSX.Element
}

// TODO: add tip on regenerating questions.

export const TipsAndTricks = ({ footer }: TipsAndTricksProps) => {

	return (
		<>
			<Typography
				variant="h2"
				textAlign="center"
				sx={{ pt: SPACING_SMALL }}
			>
				Tips and Tricks
			</Typography>

			<Box display="flex" alignItems="center" sx={{ my: SPACING_SMALL }}>
				<TextFieldsIcon fontSize="large" />
				<Typography sx={{ ml: SPACING_EXTRA_SMALL }}>
					You can generate quizzes from any text, either directly
					<Typography color="secondary" display="inline" fontWeight={700} sx={{ mx: SPACING_EXTRA_SMALL }}>
						typed, pasted, or extracted
					</Typography>
					from a PDF you upload.
				</Typography>
			</Box>

			<Box display="flex" alignItems="center" sx={{ my: SPACING_SMALL }}>
				<EditNoteIcon fontSize="large" />
				<Typography sx={{ ml: SPACING_EXTRA_SMALL }}>
					You can enter a simple
					<Typography color="secondary" display="inline" fontWeight={700} sx={{ mx: SPACING_EXTRA_SMALL }}>
						subject title
					</Typography>
					like "Student Finance", upload an entire powerpoint, or anything in-between.
				</Typography>
			</Box>

			<Box display="flex" alignItems="center" sx={{ my: SPACING_SMALL }}>
				<ShortTextIcon fontSize="large" />
				<Typography sx={{ ml: SPACING_EXTRA_SMALL }}>
					A more
					<Typography color="secondary" display="inline" fontWeight={700} sx={{ mx: SPACING_EXTRA_SMALL }}>
						concise input
					</Typography>
					will yield a more focused and
					<Typography color="secondary" display="inline" fontWeight={700} sx={{ mx: SPACING_EXTRA_SMALL }}>
						accurate quiz.
					</Typography>
					You can split your PDFs with this free <a href="https://www.ilovepdf.com/split_pdf">tool</a>.
				</Typography>
			</Box>

			<Box display="flex" alignItems="center" sx={{ my: SPACING_SMALL }}>
				<WorkspacesIcon fontSize="large" />
				<Typography sx={{ ml: SPACING_EXTRA_SMALL }}>
					It is better to generate many quizzes that
					<Typography color="secondary" display="inline" fontWeight={700} sx={{ mx: SPACING_EXTRA_SMALL }}>
						focus on one topic
					</Typography>
					than one quiz covering an entire subject.
				</Typography>
			</Box>

			<Box display="flex" alignItems="center" sx={{ my: SPACING_SMALL }}>
				<ConstructionIcon fontSize="large" />
				<Typography sx={{ ml: SPACING_EXTRA_SMALL }}>
					[Coming Soon]
					<Typography color="secondary" display="inline" fontWeight={700} sx={{ mx: SPACING_EXTRA_SMALL }}>
						chain questions
					</Typography>
					from all of your quizzes into
					<Typography color="secondary" display="inline" fontWeight={700} sx={{ mx: SPACING_EXTRA_SMALL }}>
						larger tests.
					</Typography>
				</Typography>
			</Box>

			<Box display="flex" alignItems="center" sx={{ my: SPACING_SMALL }}>
				<PublishedWithChangesIcon fontSize="large" />
				<Typography sx={{ ml: SPACING_EXTRA_SMALL }}>
					[Coming Soon]
					<Typography color="secondary" display="inline" fontWeight={700} sx={{ mx: SPACING_EXTRA_SMALL }}>
						regenerate questions
					</Typography>
					that you don't like or
					<Typography color="secondary" display="inline" fontWeight={700} sx={{ mx: SPACING_EXTRA_SMALL }}>
						edit
					</Typography>
					them yourself.
				</Typography>
			</Box>

			{footer}
		</>
	)
}