import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material"
import MenuIcon from '@mui/icons-material/Menu';
import { useContext, useState } from "react";
import useCustomNavigate from "../../utils/useCustomNavigate";
import { RIGHT_NAV_PAGES } from "./RightNav";
import { LEFT_NAV_PAGES } from "./LeftNav";
import { UserContext } from "../../utils/UserContext/UserContext";

const getPages = (isLoggedIn: boolean) => {
	return [LEFT_NAV_PAGES, RIGHT_NAV_PAGES]
		.flat()
		.filter(page => isLoggedIn ? page.showIfLoggedIn : page.showIfLoggedOut)
		.filter(page => page.label !== "Contact");
}

export const Hamburger = () => {
	const navigate = useCustomNavigate();
	const { user, signOut } = useContext(UserContext);

	const [anchorElement, setAnchorElement] = useState<HTMLElement>();
	const openMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorElement(event.currentTarget);
	const closeMenu = () => setAnchorElement(undefined);

	const handleSignOut = () => {
		closeMenu();
		signOut();
		navigate('/');
	}

	return (
		<Box>
			<IconButton
				size="large"
				onClick={openMenu}
				color="inherit"
			>
				<MenuIcon />
			</IconButton>
			<Menu
				anchorEl={anchorElement}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				open={Boolean(anchorElement)}
				onClose={closeMenu}
			>
				{getPages(Boolean(user)).map(page => (
					<MenuItem key={page.label} onClick={() => navigate(page.route)}>
						<Typography textAlign="center">{page.label}</Typography>
					</MenuItem>
				))}

				{user && (
					<MenuItem onClick={handleSignOut}>
						<Typography textAlign="center">Sign Out</Typography>
					</MenuItem>
				)}
			</Menu>
		</Box>
	)
}