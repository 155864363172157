import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material"
import { InputField } from "./InputForm"
import { InputFormData } from "./useFormDataHandler"

interface ReviewInputProps {
	fieldsToReview: InputField[]
	formData: InputFormData
}

export const ReviewInput = ({ fieldsToReview, formData }: ReviewInputProps) => {
	return (
		<TableContainer>
			<Table>
				<TableBody>
					{fieldsToReview.map((field, index) => (
						<TableRow key={field.label}>
							<TableCell
								sx={{ color: formData[field.name].toString().length <= 0 ? 'red' : 'inherit' }}
							>{field.label}</TableCell>
							<TableCell>{formData[field.name].toString().substring(0, 150)}{formData[field.name].toString().length > 150 ? '...' : ''}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}