import { useLocation } from "react-router-dom"
import { QuizView } from "../../components/QuizView/QuizView"
import { Container } from "@mui/material"
import { SPACING_NORMAL } from "../../constants"
import useCustomNavigate from "../../utils/useCustomNavigate"

export const ViewQuizPage = () => {
    const navigate = useCustomNavigate();

    const location = useLocation();
    const maybeQuizPartitionKeyValue = location.state?.quizPartitionKeyValue;
    const maybeQuizSortKeyValue = location.state?.quizSortKeyValue;

    if (maybeQuizPartitionKeyValue === undefined || maybeQuizSortKeyValue === undefined) {
        navigate('/');
    }

    return <Container sx={{ mt: SPACING_NORMAL }}>
        <QuizView quizPartitionKeyValue={maybeQuizPartitionKeyValue} quizSortKeyValue={maybeQuizSortKeyValue} />
    </Container>;
}