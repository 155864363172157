import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { LandingPageSection } from "./LandingPageSection";
import { UserContext } from "../../utils/UserContext/UserContext";
import { SPACING_EXTRA_SMALL } from "../../constants";
import { DeviceTypeContext } from "../../utils/DeviceTypeContext/DeviceTypeContext";
import useCustomNavigate from "../../utils/useCustomNavigate";

const quizSources = ["Lecture Notes", "Power Points", "PDFs", "Books", "Lessons"];

export const LandingPageATF = () => {
    const navigate = useCustomNavigate();
    const { user } = useContext(UserContext);
    const { deviceType } = useContext(DeviceTypeContext);
    const [email, setEmail] = useState<string>("");

    const handleMainButtonClick = () => {
        if (!user) return navigate('/sign-up', { state: { email } });
        navigate('/dashboard');
    };

    const handleSecondaryButtonClick = () => {
        const element = document.getElementById("try-it-out-divider");

        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }

    const mainButtonLabel = user ? 'Go To Dashboard' : 'Get Started For Free!';

    const [isAnimatingBackwards, setIsAnimatingBackwards] = useState(false);
    const [quizSourceIndex, setQuizSourceIndex] = useState(0);
    const [animatedText, setAnimatedText] = useState("");

    useEffect(() => {
        if (quizSourceIndex >= quizSources.length) return;

        const currentWord = quizSources[quizSourceIndex];
        const letterDelay = 50;
        const wordDelay = 400;

        const timerId = setInterval(() => {
            if (isAnimatingBackwards) {
                if (animatedText.length === 0) {
                    setIsAnimatingBackwards(false);
                    setQuizSourceIndex((prevIndex) => (prevIndex + 1) < quizSources.length ? prevIndex + 1 : 0);
                    clearInterval(timerId);
                } else {
                    setAnimatedText((text) => text.substring(0, text.length - 1));
                }
            } else {
                if (animatedText === currentWord) {
                    setIsAnimatingBackwards(true);
                    clearInterval(timerId);
                } else {
                    setAnimatedText((text) => text + currentWord[text.length]);
                }
            }
        }, animatedText === currentWord ? wordDelay : letterDelay);

        return () => clearInterval(timerId);
    }, [animatedText, quizSourceIndex, isAnimatingBackwards]);

    return (
        <LandingPageSection>
            <Grid container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Box>
                    <Typography variant="h1" align="center">
                        AI generated quizzes from your
                    </Typography>
                    <Typography variant="h1" color="secondary" align="center" sx={{ height: "2em" }}>
                        {animatedText ?? " "}
                    </Typography>

                    <Grid container justifyContent="space-between">
                        {!user && (
                            <Grid item xs={deviceType === 'MOBILE' ? 12 : 7}>
                                <TextField
                                    sx={{ paddingBottom: SPACING_EXTRA_SMALL }}
                                    onChange={(e) => setEmail(e.target.value)}
                                    label="Email"
                                    fullWidth
                                />
                            </Grid>
                        )}
                        <Grid item xs={deviceType === 'MOBILE' ? 12 : !user ? 4 : 12}>
                            <Button
                                onClick={handleMainButtonClick}
                                variant="contained"
                                color="secondary"
                                size="large"
                                fullWidth
                                sx={{ height: deviceType === 'MOBILE' ? "3em" : "3.6em" }}
                            >
                                {mainButtonLabel}
                            </Button>
                        </Grid>
                    </Grid>

                    {user === undefined && (
                        <Button
                            onClick={handleSecondaryButtonClick}
                            fullWidth
                        >
                            Or try it out first!
                        </Button>
                    )}
                </Box>
            </Grid>
        </LandingPageSection>
    );
}
