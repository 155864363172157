import { Box, Button, Modal, Typography } from "@mui/material";
import { BORDER_RADIUS, BOX_SHADOW, SPACING_SMALL } from "../../constants";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    backgroundColor: 'white',
    borderRadius: BORDER_RADIUS,
    boxShadow: BOX_SHADOW,
    padding: SPACING_SMALL,
    border: '2px solid black',
};

interface DisclaimerModalProps {
    disclaimer: string
    visible: boolean
    onAccept: () => void
}

export const DisclaimerModal = ({ disclaimer, visible, onAccept }: DisclaimerModalProps) => {

    return (
        <Modal
            open={visible}
        >
            <Box sx={style}>
                <Typography variant="h6" component="h2">
                    Disclaimer
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    {disclaimer}
                </Typography>

                <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    sx={{ mt: SPACING_SMALL }}
                    onClick={onAccept}
                >
                    I Accept
                </Button>
            </Box>
        </Modal>
    )
}