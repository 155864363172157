import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { UserContext } from "../../utils/UserContext/UserContext";
import { useContext, useState } from "react";
import { DeviceTypeContext } from "../../utils/DeviceTypeContext/DeviceTypeContext";
import useCustomNavigate from "../../utils/useCustomNavigate";

export const RIGHT_NAV_PAGES = [
    { label: 'My Account', route: '/account', showIfLoggedIn: true, showIfLoggedOut: false },
    { label: 'Sign In', route: '/sign-in', showIfLoggedIn: false, showIfLoggedOut: true },
];

const getPages = (isLoggedIn: boolean) => {
    return RIGHT_NAV_PAGES.filter(page => isLoggedIn ? page.showIfLoggedIn : page.showIfLoggedOut);
}

export const RightNav = () => {
    const [anchorElement, setAnchorElement] = useState<HTMLElement>();
    const { user, signOut } = useContext(UserContext);
    const { deviceType } = useContext(DeviceTypeContext);
    const navigate = useCustomNavigate();

    const openMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorElement(event.currentTarget);
    const closeMenu = () => setAnchorElement(undefined);
    const handleSignOut = () => {
        closeMenu();
        signOut();
        navigate('/');
    }

    const handleSignUp = () => {
        navigate("/sign-up")
    }

    return (
        <Box sx={{ flexGrow: 0 }}>
            {user ? (
                <IconButton onClick={openMenu}>
                    <Avatar />
                </IconButton>
            ) : (
                <>
                    {deviceType === 'DESKTOP' && (
                        <Button
                            onClick={handleSignUp}
                            variant="text"
                            color="secondary"
                        >
                            Sign Up
                        </Button>
                    )}
                    <Button
                        onClick={() => navigate('/sign-in')}
                        variant="contained"
                        color="secondary"
                    >
                        Sign In
                    </Button>
                </>
            )}

            <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElement}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElement)}
                onClose={closeMenu}
            >
                {getPages(Boolean(user)).map(page => (
                    <MenuItem key={page.label} onClick={() => navigate(page.route)}>
                        <Typography textAlign="center">{page.label}</Typography>
                    </MenuItem>
                ))}

                {user && (
                    <MenuItem onClick={handleSignOut}>
                        <Typography textAlign="center">Sign Out</Typography>
                    </MenuItem>
                )}
            </Menu>
        </Box>
    )
}