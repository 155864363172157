import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../utils/UserContext/UserContext";
import axios from "axios";
import { GET_OWNED_QUIZZES } from "../../constants";
import { PopUpContext } from "../../utils/PopUpContext/PopUpContext";
import { Quiz } from "../../utils/useBrowseManager";

export const useQuizzesManager = () => {
    const { user } = useContext(UserContext);
    const { setPopUp } = useContext(PopUpContext);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [quizzes, setQuizzes] = useState<Quiz[]>();

    const updateQuizzes = async () => {
        const config = {
            headers: {
                authorization: user?.accessToken
            }
        };

        setIsLoading(true);

        try {
            const response = await axios.post(GET_OWNED_QUIZZES, {}, config)
            setQuizzes(response.data as Quiz[]);
            setIsLoading(false);
        } catch (error: any) {
            setPopUp({ message: error.response.data.message, severity: 'error' });
            setIsLoading(false);
        }
    }

    useEffect(() => {
        updateQuizzes();
        // Disable missing dependency warning to prevent the table refreshing quickly
        // as a new accessToken is granted after a page load using refresh token.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { quizzes, isLoading, updateQuizzes };
};