import { useEffect, useState } from "react";
import Confetti from 'react-confetti';

export const RainConfetti = () => {
	const [isRaining, setIsRaining] = useState(false);

	useEffect(() => {
		setIsRaining(true);
		const timer = setTimeout(() => setIsRaining(false), 4000);

		return () => clearTimeout(timer);
	}, []);

	return (
		<div style={{
			position: 'fixed',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			pointerEvents: 'none'
		}}>
			{isRaining && <Confetti width={window.innerWidth} height={window.innerHeight} />}
		</div>
	);
};