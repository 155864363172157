import { Box, Container, Typography } from "@mui/material";
import { SPACING_NORMAL } from "../../constants";

export const PrivacyPolicyPage = () => {

    return (
        <Container maxWidth="md" sx={{ mt: SPACING_NORMAL }}>
            <Typography variant="h1">Privacy Policy</Typography>

            <Box sx={{ my: SPACING_NORMAL }}>
                <Typography variant="h2">Introduction</Typography>
                This page serves to inform you of our policies regarding the collection,
                use, and disclosure of personal information we receive from users of our
                platform. We understand the importance of privacy, and we are committed
                to protecting your data.
            </Box>

            <Box sx={{ my: SPACING_NORMAL }}>
                <Typography variant="h2">Information We Collect</Typography>

                <Typography variant="h3">Information Provided by You</Typography>
                We collect minimum amount of personally identifiable information required to provide the
                core functionality of the website. The information we collect is listed below:

                <ul>
                    <li>Email Address</li>
                    <li>Password</li>
                    <li>Date of Birth</li>
                </ul>

                You also provide to us a textual input for quiz generation (either manually typed, or extracted
                from a PDF file). This content is used only for quiz generation purposes, and after generation is
                instantly deleted from our servers. We only store the generated quiz. As a user of this site,
                you accept to only input non-copyrighted and non Intellectual Property protected content.

                <Typography variant="h3">Information Collected Automatically</Typography>
                We automatically collect certain information when you visit, use, or navigate the Services.
                This information does not reveal your specific identity (like your name or contact information)
                but may include device and usage information, such as your IP address, browser and device
                characteristics, operating system, language preferences, referring URLs, device name, country,
                location, information about how and when you use our Services, and other technical information.

                This information is primarily needed to maintain the security and operation of our Services,
                and for our internal analytics and reporting purposes.
            </Box>

            <Box sx={{ my: SPACING_NORMAL }}>
                <Typography variant="h2">How We Use Collected Information</Typography>
                The information we collect is used for various purposes required for the core
                website functionality. This includes but is not limited to:

                <ul>
                    <li>Registering for, authenticating with, and deleting your user account.</li>
                    <li>Feeding our AI with your input to generate personalised AI quizzes.</li>
                    <li>Sharing quizzes with other users.</li>
                    <li>Improving our services and enhancing your overall user experience.</li>
                </ul>
            </Box>

            <Box sx={{ my: SPACING_NORMAL }}>
                <Typography variant="h2">Data Sharing and Disclosure</Typography>
                We only share your information with trusted third parties for specific purposes
                required to provide the core functionality of this website.

                The current third parties we share information with are listed below:
                <ul>
                    <li>Amazon AWS, for user authentication, storing of quizzes, etc.</li>
                    <li>OpenAI, for the generation of quizzes.</li>
                </ul>

                We do not share your information with any other third parties. We will notify you of
                any changes via the process laid out in section 'Changes to this Privacy Policy' below.

                We do not sell or rent your information to any other third party for advertising
                or other purposes, other than those explicitly listed above.
            </Box>

            <Box sx={{ my: SPACING_NORMAL }}>
                <Typography variant="h2">User Choices and Control</Typography>
                You have the right to access, update, and delete any information we have collected from you.
                If you would like to do so, please visit https://www.quizletic.com/account.
                If you have a request to change any data not editable on this page please contact
                us directly at https://www.quizletic.com/contact-us.
            </Box>

            <Box sx={{ my: SPACING_NORMAL }}>
                <Typography variant="h2">Security Measures</Typography>
                We have implemented robust measures
                to protect your information from unauthorized access, alteration, disclosure, or
                destruction. These measures include encryption, regular security audits, and
                access controls.
            </Box>

            <Box sx={{ my: SPACING_NORMAL }}>
                <Typography variant="h2">Third-Party Links</Typography>
                Our Privacy Policy applies solely to information collected by our platform.
                We may provide links to third-party websites for your convenience. We have no control
                over these websites and cannot be held responsible
                for their privacy practices. We encourage you to review the privacy policies of
                any third-party sites you visit.
            </Box>

            <Box sx={{ my: SPACING_NORMAL }}>
                <Typography variant="h2">Changes to this Privacy Policy</Typography>
                We may update our Privacy Policy from time to time to reflect changes in our
                practices or for other operational, legal, or regulatory reasons. Any changes
                will be communicated to you through the platform, and we encourage you to review
                the updated policy. Your continued use of our services after any changes
                indicates your acceptance of the revised policy.
            </Box>

            <Box sx={{ my: SPACING_NORMAL }}>
                <Typography variant="h2">Contact Us</Typography>
                If you have any questions, concerns, or requests regarding your privacy or
                our Privacy Policy, please do not hesitate to contact us. You can reach us
                at https://www.quizletic.com/contact-us and we will do our best to address
                your inquiries in a timely manner.
            </Box>
        </Container>
    )
}