import { Button, Container, Typography } from "@mui/material"
import { LandingPageSection } from "./LandingPageSection"
import { FONT_EXTRA_SMALL, SPACING_SMALL } from "../../constants"
import { CreateQuizInput } from "./CreateQuizInput";
import { Question } from "../../components/QuizView/QuestionView";
import { useState } from "react";
import { QuizView } from "../../components/QuizView/QuizView";

export const TryItOut = () => {
    const [questions, setQuestions] = useState<Question[]>()

    return (
        <LandingPageSection>
            <Typography variant="h1" textAlign="center" sx={{ width: '100%' }}>Try it Out!</Typography>
            <Typography textAlign="center" sx={{ mt: SPACING_SMALL, width: '100%' }}>
                Generate a sample quiz from one of our curated inputs or write your own to see our AI in action.
            </Typography>
            <Typography textAlign="center" sx={{ mb: SPACING_SMALL, width: '100%', fontSize: FONT_EXTRA_SMALL, fontWeight: "bold" }}>
                Your IP address will be stored on our system for up to 30 days to prevent over-usage of this feature.
            </Typography>

            {questions === undefined ? (
                <CreateQuizInput setQuestions={setQuestions} />
            ) : (
                <>
                    <QuizView exampleQuiz={{ questions }} />
                    <Container sx={{ textAlign: 'center' }}>
                        <Button
                            onClick={() => setQuestions(undefined)}
                            sx={{ textDecoration: 'underline' }}
                        >
                            Generate another quiz!
                        </Button>
                    </Container>
                </>
            )}
        </LandingPageSection>
    )
}