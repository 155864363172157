import { Button, Grid } from "@mui/material";
import { BrowseOption, Quiz } from "../../utils/useBrowseManager";

const LAPTOP_NUM_COLUMNS = 4;
const TABLET_NUM_COLUMNS = 2;
const PHONE_NUM_COLUMNS = 1;

interface BrowseButtonProps {
    item: BrowseOption
    onFilterButtonClick: (item: BrowseOption | Quiz) => void
}

export const BrowseButton = ({ item, onFilterButtonClick }: BrowseButtonProps) => (
    <Grid item xs={12 / PHONE_NUM_COLUMNS} sm={12 / TABLET_NUM_COLUMNS} md={12 / LAPTOP_NUM_COLUMNS}>
        <Button
            variant="outlined"
            fullWidth
            size='large'
            onClick={() => onFilterButtonClick(item)}
            sx={{ height: '100%' }}
        >
            {item.name}
        </Button>
    </Grid>
)