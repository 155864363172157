import { Box, Button, Container, Grid, Typography } from "@mui/material"
import { SPACING_EXTRA_SMALL, SPACING_SMALL } from "../../constants";
import { Quiz } from "../../utils/useBrowseManager";
import VisibilityIcon from '@mui/icons-material/Visibility';
import useCustomNavigate from "../../utils/useCustomNavigate";

interface QuizButtonProps {
    quiz: Quiz
}

const LAPTOP_NUM_COLUMNS = 3;
const TABLET_NUM_COLUMNS = 2;
const PHONE_NUM_COLUMNS = 1;

export const QuizButton = ({ quiz }: QuizButtonProps) => {
    const navigate = useCustomNavigate();

    return (
        <Grid item xs={12 / PHONE_NUM_COLUMNS} sm={12 / TABLET_NUM_COLUMNS} md={12 / LAPTOP_NUM_COLUMNS}>
            <Button
                variant="outlined"
                fullWidth
                size='large'
                onClick={() => navigate('/view-quiz', {
                    state: {
                        quizPartitionKeyValue: quiz.educationInstitutionCourseYearModuleId,
                        quizSortKeyValue: quiz.id
                    }
                })}
                sx={{ height: '100%', paddingX: SPACING_EXTRA_SMALL }}
            >
                <Box sx={{ display: 'flex', width: "100%", flexDirection: 'column', height: '100%', overflow: 'hidden' }}>
                    <Typography sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        marginBottom: SPACING_SMALL,
                        textAlign: "left"
                    }}>
                        {quiz.name}
                    </Typography>

                    <Container disableGutters sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                        <Box>
                            <Typography>
                                {new Date(quiz.timeCreated).toLocaleString()}
                            </Typography>
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <VisibilityIcon />
                            <Typography ml={1}>
                                {quiz.views}
                            </Typography>
                        </Box>
                    </Container>
                </Box>
            </Button>
        </Grid>
    )
}