import { Button, ButtonProps, CircularProgress } from "@mui/material"
import { useContext, useState } from "react";
import { UserContext } from "../../utils/UserContext/UserContext";
import { PopUpContext } from "../../utils/PopUpContext/PopUpContext";
import axios from "axios";
import { isValidFormData } from "../InputForm/inputValidationUtils";

interface AjaxButtonProps {
    muiButtonProps?: ButtonProps
    label: string,
    submitUrl: string,
    requireUserToSubmit: boolean
    body?: any,
    successCallback?: (data: any, request?: any) => void,
    showSuccessPopUp?: boolean
    showErrorPopUp?: boolean
    shouldValidateInput?: boolean
    session?: string
}

export const AjaxButton = ({
    muiButtonProps,
    label,
    submitUrl,
    body,
    successCallback,
    requireUserToSubmit,
    showSuccessPopUp = true,
    showErrorPopUp = true,
    shouldValidateInput = false,
    session
}: AjaxButtonProps) => {
    const { user } = useContext(UserContext);
    const { setPopUp } = useContext(PopUpContext);
    const [isAjaxCallInProgress, setIsAjaxCallInProgress] = useState(false);

    const handleSubmit = async () => {
        if (requireUserToSubmit && !user) {
            return setPopUp({ severity: 'error', message: 'You must be signed in to do that.' });
        }

        if (shouldValidateInput) {
            const validFormData = isValidFormData(body);
            if (validFormData !== true) {
                return setPopUp({ message: validFormData, severity: 'error' });
            }
        }

        const config = requireUserToSubmit ? {
            headers: {
                authorization: user?.accessToken,
            },
        } : undefined;

        if (session !== undefined && body !== undefined) body.session = session;

        setIsAjaxCallInProgress(true);

        try {
            const response = await axios.post(submitUrl, body, config);
            setIsAjaxCallInProgress(false);
            if (showSuccessPopUp && response?.data?.message) setPopUp({ message: response.data.message, severity: 'success' });
            if (successCallback) successCallback(response.data, body);
        } catch (error: any) {
            console.error(error);
            setIsAjaxCallInProgress(false);
            if (showErrorPopUp && error.response.data.message) {
                setPopUp({ message: error.response.data.message, severity: 'error' });
            }
        }
    }

    const disabled = muiButtonProps?.disabled !== undefined ?
        muiButtonProps.disabled || isAjaxCallInProgress :
        isAjaxCallInProgress;

    return (
        <Button
            {...muiButtonProps}
            onClick={handleSubmit}
            disabled={disabled}
            endIcon={isAjaxCallInProgress && <CircularProgress size="0.75em" />}
        >
            {label}
        </Button>
    )
}