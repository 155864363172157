import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { FONT_EXTRA_SMALL, SIGN_IN } from '../../constants';
import { UserContext } from '../../utils/UserContext/UserContext';
import { useContext, useEffect } from 'react';
import { InputForm, InputFormSection } from '../../components/InputForm/InputForm';
import useCustomNavigate from '../../utils/useCustomNavigate';
import { useLocation } from 'react-router-dom';

export const SignInPage = () => {
    const navigate = useCustomNavigate();
    const location = useLocation();
    const maybeEmail = location.state?.email;

    const { user, signIn } = useContext(UserContext);
    useEffect(() => { if (user) navigate('/') }, [user, navigate]);

    const handleLogin = (data: any) => {
        signIn(data.idToken, data.accessToken, data.refreshToken);
        navigate('/dashboard');
    }

    const handleSignUp = () => {
        navigate("/sign-up")
    }

    const footerActions = (
        <Grid container mt={3}>
            <Grid item xs>
                <Button onClick={() => navigate("/forgot-password")} variant="text" sx={{ px: 0, textDecoration: "underline", fontSize: FONT_EXTRA_SMALL }}>
                    Forgot Password?
                </Button>
            </Grid>
            <Grid item>
                <Button onClick={handleSignUp} variant="text" sx={{ px: 0, textDecoration: "underline", fontSize: FONT_EXTRA_SMALL }}>
                    Sign Up
                </Button>
            </Grid>
        </Grid>
    )

    const sections: InputFormSection[] = [{
        inputFields: [
            { label: 'Email', name: 'email', type: 'TEXT', defaultValue: maybeEmail },
            { label: 'Password', name: 'password', type: 'TEXT' }
        ]
    }];

    return (
        <InputForm
            title='Sign In'
            sections={sections}
            submitLabel='Sign In'
            submitEndpoint={SIGN_IN}
            submitResponseCallback={handleLogin}
            footerActions={footerActions}
            requireUserToSubmit={false}
        />
    )
}