import { createContext } from 'react';

export interface Cache { [key: string]: any }

interface ICacheContext {
    cache: Cache
    setCacheValue: (key: string, value: any) => void;
    clearCache: () => void;
}

export const CacheContext = createContext<ICacheContext>({
    cache: {},
    setCacheValue: (key: string, value: any) => { },
    clearCache: () => { }
});