import React, { useContext } from 'react';
import { Box, Button, Container, Grid, Typography, useTheme } from "@mui/material";
import { SPACING_EXTRA_SMALL, SPACING_LARGE, SPACING_NORMAL, SPACING_SMALL } from "../../constants";
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import QuizIcon from '@mui/icons-material/Quiz';
import useCustomNavigate from '../../utils/useCustomNavigate';
import { UserContext } from '../../utils/UserContext/UserContext';
import { DeviceTypeContext } from '../../utils/DeviceTypeContext/DeviceTypeContext';

const LAPTOP_NUM_COLUMNS = 3;
const TABLET_NUM_COLUMNS = 2;
const PHONE_NUM_COLUMNS = 1;

const ICON_PROPS = { fontSize: 75, mt: SPACING_EXTRA_SMALL };


export const SellingPoints = () => {
    const navigate = useCustomNavigate();
    const { user } = useContext(UserContext);
    const { deviceType } = useContext(DeviceTypeContext);

    const theme = useTheme();

    const SELLING_POINTS = [
        {
            title: 'Learn During Class',
            subTitle: 'Listen and learn by engaging with course materials during class.',
            icon: <LocalLibraryIcon sx={ICON_PROPS} />,
        },
        {
            title: 'Upload Lecture Notes',
            subTitle: 'Quizletic will take your lecture notes and generate a personalised quiz in seconds.',
            icon: <CloudUploadIcon sx={ICON_PROPS} />,
        },
        {
            title: `Practise With Quizzes`,
            subTitle: 'Solidify knowledge by taking your quiz, and fill gaps with our detailed feedback.',
            icon: <QuizIcon sx={ICON_PROPS} />,
        }
    ]

    const handleMainButtonClick = () => {
        if (!user) return navigate('/sign-up');
        navigate('/dashboard');
    }

    return (
        <Box sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            padding: SPACING_SMALL
        }}>

            <Container>
                <Typography variant="h2" textAlign="center" sx={{ mb: SPACING_LARGE }}>
                    Quizletic <span style={{ color: theme.palette.success.main }}>eliminates</span> the <span style={{ color: theme.palette.error.main }}>time-consuming</span> and <span style={{ color: theme.palette.error.main }}>monotonous</span> elements of studying.
                </Typography>

                <Grid container spacing={SPACING_NORMAL}>
                    {SELLING_POINTS.map((sellingPoint, index) => (
                        <Grid item
                            key={index}
                            xs={12 / PHONE_NUM_COLUMNS}
                            sm={12 / TABLET_NUM_COLUMNS}
                            md={12 / LAPTOP_NUM_COLUMNS}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center',
                            }}
                        >
                            <Box
                                sx={{
                                    backgroundColor: theme.palette.secondary.main,
                                    color: '#FFFFFF',
                                    borderRadius: '50%',
                                    width: '2em',
                                    height: '2em',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginBottom: SPACING_EXTRA_SMALL,
                                }}
                            >
                                <Typography variant="h4">{index + 1}</Typography>
                            </Box>
                            <Typography variant="h3">{sellingPoint.title}</Typography>
                            {sellingPoint.icon}
                            <Typography>{sellingPoint.subTitle}</Typography>
                        </Grid>
                    ))}
                </Grid>

                <Typography variant="h2" textAlign="center" sx={{ mt: SPACING_LARGE }}>
                    And <span style={{ color: theme.palette.error.main }}>unlike our competitors</span>, Quizletic is entirely <span style={{ color: '#D4AF37' }}>FREE TO USE</span>!
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mt: SPACING_NORMAL
                    }}
                >
                    <Button
                        color="secondary"
                        onClick={handleMainButtonClick}
                        variant="contained"
                        size="large"
                        sx={{
                            height: deviceType === 'MOBILE' ? "3em" : "3.6em",
                            width: deviceType === 'MOBILE' ? "80%" : "50%",
                            padding: SPACING_EXTRA_SMALL,
                            fontSize: "1.3rem"
                        }}
                    >
                        Start Using For Free!
                    </Button>
                </Box>
            </Container>
        </Box>
    )
}
