import { useMemo, useState } from "react";
import { InputFormSection } from "./InputForm";

export interface InputFormData {
	[key: string]: string | number | boolean | File;
}

export const useFormDataHandler = (sections: InputFormSection[]) => {
	const initialFormData = useMemo(() => {
		return sections.map(section => section.inputFields).flat()?.reduce((acc, inputField) => {
			return { ...acc, [inputField.name]: inputField.type === 'CHECKBOX' ? false : inputField.defaultValue ?? '' };
		}, {}) as InputFormData;
	}, [sections]);

	const [formData, setFormData] = useState<InputFormData>(initialFormData);

	const allInputFields = sections.map(section => section.inputFields).flat();
	const nonCompletedFields = allInputFields.filter(field =>
		formData[field.name].toString().length <= 0
		|| (field.type === "REQUIRED_CHECKBOX" && formData[field.name] !== true)
	);
	const isFormDataComplete = nonCompletedFields.length === 0;

	return { formData, setFormData, allInputFields, isFormDataComplete };
};