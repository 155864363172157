import { useContext } from "react";
import { TryItOut } from "./TryItOut";
import { UserContext } from "../../utils/UserContext/UserContext";
import { Container } from "@mui/material";
import { SellingPoints } from "./SellingPoints";
import { Features } from "./Features";
import { Divider } from './Divider';

export const LandingPageBTF = () => {
    const { user } = useContext(UserContext);

    return (
        <>
            <Divider />
            <SellingPoints />
            <Divider inverted id="try-it-out-divider" />

            <Container>
                {user === undefined && <TryItOut />}
                <Features />
            </Container>
        </>
    );
}