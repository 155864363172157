import { Button, Grid } from "@mui/material"
import { AjaxButton } from "../AjaxButton/AjaxButton"
import { SPACING_SMALL } from "../../constants"
import { InputFormSection } from "./InputForm"
import { InputFormData } from "./useFormDataHandler"

interface InputFormFooterProps {
    sections: InputFormSection[]
    currentSectionIndex: number
    hasFinishedMultiPartForm: boolean
    submitLabel: string
    submitEndpoint: string
    requireUserToSubmit: boolean
    formData: InputFormData
    isFormDataComplete: boolean
    footerActions?: JSX.Element
    submitResponseCallback?: (data: any, body?: any) => void;
    previousSection: () => void
    nextSection: () => void
    session?: string
}

export const InputFormFooter = ({
    sections,
    currentSectionIndex,
    hasFinishedMultiPartForm,
    submitLabel,
    submitEndpoint,
    requireUserToSubmit,
    formData,
    isFormDataComplete,
    submitResponseCallback,
    previousSection,
    nextSection,
    session
}: InputFormFooterProps) => {
    const shouldRenderSubmitButton = sections.length === 1 || (sections.length > 1 && hasFinishedMultiPartForm);

    return (
        <>
            <Grid container justifyContent={'space-between'} sx={{ mt: SPACING_SMALL }}>
                {currentSectionIndex > 0 &&
                    <Grid item><Button onClick={previousSection}>Back</Button></Grid>
                }

                {sections.length > 1 && !hasFinishedMultiPartForm &&
                    <Grid item sx={{ marginLeft: 'auto' }}>
                        <Button
                            variant='contained'
                            color='secondary'
                            onClick={nextSection}
                        >
                            Next
                        </Button>
                    </Grid>
                }

                {shouldRenderSubmitButton &&
                    <Grid item sx={{ marginLeft: 'auto' }} xs={sections.length > 1 ? undefined : 12}>
                        <AjaxButton
                            label={submitLabel}
                            body={formData}
                            submitUrl={submitEndpoint}
                            successCallback={submitResponseCallback}
                            shouldValidateInput={true}
                            requireUserToSubmit={requireUserToSubmit}
                            session={session}
                            muiButtonProps={{
                                variant: "contained",
                                color: "secondary",
                                disabled: !isFormDataComplete,
                                fullWidth: true
                            }}
                        />
                    </Grid>
                }
            </Grid>
        </>
    )
}