import { Button, Grid, TextField } from "@mui/material"
import { CREATE_SAMPLE_QUIZ, SPACING_EXTRA_SMALL, SPACING_NORMAL } from "../../constants"

import TerminalIcon from '@mui/icons-material/Terminal';
import PsychologyIcon from '@mui/icons-material/Psychology';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { Dispatch, useContext, useState } from "react";
import { RandomStringLoader } from "../../components/RandomStringLoader/RandomStringLoader";
import axios from "axios";
import { PopUpContext } from "../../utils/PopUpContext/PopUpContext";
import { Question } from "../../components/QuizView/QuestionView";

const MAX_CHARACTERS = 400;

const subjects = [
    {
        title: 'English',
        icon: <TextSnippetIcon />,
        backgroundColor: 'orange',
        quizInputText: `In poetry, the rhyme scheme defines the pattern of rhymes in a poem. It adds structure and musicality to the verses.\n\nUnlike many of his sonnets, Shakespeare's 'To be or not to be' lacks a traditional rhyme scheme. The absence of rhyming couplets allows the focus to shift to the profound exploration of life, death, and existential questioning.`
    },
    {
        title: 'Physics',
        icon: <RocketLaunchIcon />,
        backgroundColor: 'maroon',
        quizInputText: `Stars form from collapsing molecular clouds, initiating the fusion process for millions to billions of years. During fusion, hydrogen converts to helium, sustaining the stars brightness.\n\nEventually, smaller stars, like the Sun, expand into red giants, while massive stars end in explosive supernovae, leaving neutron stars or black holes.`
    },
    {
        title: 'Psychology',
        icon: <PsychologyIcon />,
        backgroundColor: 'purple',
        quizInputText: `Memory encodes, stores, and retrieves information in short-term and long-term forms.\n\nShort-term memory relies on rehearsal, while long-term memory consolidates information for extended storage through synaptic changes, making it durable. Both are susceptible to interference, shaping cognition and behaviour.`
    },
    {
        title: 'Computer Science',
        icon: <TerminalIcon />,
        backgroundColor: 'black',
        quizInputText: `Linked lists and arrays are data structures for storing lists of data.\n\nArrays store elements in contiguous memory, enabling fast random access but have a fixed size. Linked lists consist of nodes with pointers, allowing dynamic sizing and efficient insertions/deletions, but slower access times. Optimal choice depends on specific use case requirements.`
    },
    {
        title: 'Economics',
        icon: <ShowChartIcon />,
        backgroundColor: 'steelblue',
        quizInputText: `Market structures in economics dictate how firms operate and influence competition.\n\nIn a competitive market, numerous sellers offer similar products, fostering price competition. In contrast, monopolies feature a single dominating seller, controlling prices.\n\nUnderstanding these structures is vital for analyzing economic efficiency and consumer welfare.`
    },
]

interface CreateQuizInputProps {
    setQuestions: Dispatch<React.SetStateAction<Question[] | undefined>>
}

export const CreateQuizInput = ({ setQuestions }: CreateQuizInputProps) => {
    const { setPopUp } = useContext(PopUpContext);

    const [createQuizInProgress, setCreateQuizInProgress] = useState<boolean>(false);
    const [quizInputText, setQuizInputText] = useState<string>('');

    const createQuiz = async () => {
        setCreateQuizInProgress(true);

        try {
            const response = await axios.post(CREATE_SAMPLE_QUIZ, { quizInputText });
            setCreateQuizInProgress(false);
            setQuestions(response.data as Question[]);
        } catch (error: any) {
            console.error(error);
            setCreateQuizInProgress(false);
            setPopUp({ message: error?.response?.data?.message ?? 'Unknown error. Please try again later.', severity: 'error' });
        }
    }

    if (createQuizInProgress) return (
        <RandomStringLoader />
    )

    return (
        <>
            <Grid container sx={{
                marginBottom: SPACING_NORMAL,
                justifyContent: "center",
                rowGap: SPACING_EXTRA_SMALL,
                columnGap: SPACING_EXTRA_SMALL
            }}>
                {subjects.map((subject, index) =>
                    <Grid item key={index}>
                        <Button
                            startIcon={subject.icon}
                            variant="contained"
                            sx={{
                                backgroundColor: subject.backgroundColor,
                                borderBottom: quizInputText === subjects[index].quizInputText ? "3px solid #59BFFF" : undefined,
                            }}
                            onClick={() => setQuizInputText(subjects[index].quizInputText)}
                        >
                            {subject.title}
                        </Button>
                    </Grid>
                )}
            </Grid>

            <TextField
                required
                fullWidth
                multiline
                rows={5}
                label="What would you like to learn?"
                value={quizInputText}
                onChange={(event) => setQuizInputText(event.target.value)}
                inputProps={{ maxLength: MAX_CHARACTERS }}
                helperText={`${quizInputText.length}/${MAX_CHARACTERS} characters`}
                sx={{ "& .MuiFormHelperText-root": { textAlign: "center" } }}
            />

            <Button
                fullWidth
                variant="contained"
                color="secondary"
                sx={{ mt: SPACING_EXTRA_SMALL }}
                onClick={createQuiz}
                disabled={quizInputText === undefined || quizInputText.length === 0}
            >
                Create Quiz
            </Button>
        </>
    )
}