import axios from "axios";
import { Cache } from "./CacheContext/CacheContext"

export const fetchWithLocalCache = async (
    endpoint: string,
    body: any,
    cache: Cache,
    setCacheValue: (key: string, value: any) => void,
    config?: any
) => {
    const cacheKey = `${endpoint}:${Object.values(body).join(':')}`;
    if (cache[cacheKey] !== undefined) return cache[cacheKey];

    const response = await axios.post(endpoint, body, config);
    const data: any = response.data;
    setCacheValue(cacheKey, data);

    return data;
}