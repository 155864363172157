import { SPACING_EXTRA_SMALL, SPACING_SMALL } from "../../constants"
import { Autocomplete, Box, Button, TextField } from "@mui/material"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { CenteredLoadingSpinner } from "../../components/CentredLoadingSpinner/CentredLoadingSpinner";
import { BrowseInput, BrowseOption, EDUCATION_TYPE_INDEX, YEAR_INDEX } from "../../utils/useBrowseManager";

const stringToId = (str: string) => str.replace(/[^\w\s-]/g, '').replace(/\s+/g, '-').replace(/-+/g, '-').toLowerCase();

interface DynamicDropdownsProps {
    browseInputs: BrowseInput[]
    isNextBrowseInputLoading: boolean
    onBrowseInputChange: (index: number, newSelectedValueId?: string | undefined) => Promise<void>
}

export const DynamicDropdowns = ({ browseInputs, isNextBrowseInputLoading, onBrowseInputChange }: DynamicDropdownsProps) => {

    const onAutoCompleteChange = (index: number, newValue: string | BrowseOption | null) => {
        const newSelectedValueId = typeof newValue === 'string' ? stringToId(newValue) : newValue?.id;
        onBrowseInputChange(index, newSelectedValueId)
    }

    return (
        <>
            {browseInputs.map((browseInput: BrowseInput, index: number) => {
                const allowCustomInput = index !== EDUCATION_TYPE_INDEX && index !== YEAR_INDEX;
                const isLastDropdown = index + 1 === browseInputs.length;

                return (
                    <Box key={index} sx={{ my: SPACING_SMALL, display: "flex" }}>
                        <Autocomplete
                            fullWidth
                            options={browseInput.possibleValues}
                            getOptionLabel={(option) => {
                                if (typeof option === 'string') {
                                    return option;
                                }
                                return option.name;
                            }}
                            onChange={(_, newValue) => onAutoCompleteChange(index, newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={browseInput.name}
                                />
                            )}
                            freeSolo={allowCustomInput}
                            autoSelect={allowCustomInput}
                            disabled={index !== EDUCATION_TYPE_INDEX && browseInputs[index - 1].selectedValueId === undefined}
                        />

                        {(isLastDropdown && allowCustomInput && !isNextBrowseInputLoading) && (
                            <Button variant="contained" color="secondary" sx={{ ml: SPACING_EXTRA_SMALL }}>
                                <ArrowForwardIcon />
                            </Button>
                        )}
                    </Box>
                )
            })}

            {isNextBrowseInputLoading && (
                <CenteredLoadingSpinner />
            )}
        </>
    )
}