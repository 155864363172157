import { useContext, useState } from "react"
import { CacheContext, Cache } from "./CacheContext/CacheContext"
import { GET_COURSES, GET_INSTITUTIONS, GET_MODULES, GET_QUIZZES } from "../constants"
import { fetchWithLocalCache } from "./fetchWithLocalCache"

export interface BrowseOption {
    id: string
    name: string
}

export interface BrowseInput {
    name: string
    id: string
    possibleValues: BrowseOption[] | Quiz[]
    selectedValueId?: string
}

export interface Quiz {
    educationInstitutionCourseYearModuleId: string,
    id: string,
    timeCreated: number,
    views: number,
    questionIds: string[]
    name: string
    ownerId: string
}

export const EDUCATION_TYPE_INDEX = 0;
export const INSTITUTION_INDEX = 1;
export const COURSE_INDEX = 2;
export const YEAR_INDEX = 3;
export const MODULE_INDEX = 4;
export const QUIZ_INDEX = 5

const EDUCATION_TYPES: BrowseOption[] = [
    { id: 'university', name: 'University' },
    { id: 'alevel', name: 'A Level' },
    { id: 'gcse', name: 'GCSE' },
]

const YEARS = (educationType: string): BrowseOption[] => {
    if (educationType === 'university') {
        return [
            { name: 'Year 0', id: 'year-0' },
            { name: 'Year 1', id: 'year-1' },
            { name: 'Year 2', id: 'year-2' },
            { name: 'Year 3', id: 'year-3' },
            { name: 'Year 4', id: 'year-4' },
        ]
    } else if (educationType === 'alevel') {
        return [
            { name: 'Year 12', id: 'year-12' },
            { name: 'Year 13', id: 'year-13' }
        ]
    } else if (educationType === 'gcse') {
        return [
            { name: 'Year 7', id: 'year-7' },
            { name: 'Year 8', id: 'year-8' },
            { name: 'Year 9', id: 'year-9' },
            { name: 'Year 10', id: 'year-10' },
            { name: 'Year 11', id: 'year-11' }
        ]
    } else return [];
}

const removeFutureBrowseInputs = (currentBrowseInputIndex: number, browseInputs: BrowseInput[]) => {
    browseInputs.splice(currentBrowseInputIndex + 1)
}

const addNextBrowseInput = async (
    currentBrowseInputIndex: number,
    browseInputs: BrowseInput[],
    cache: Cache,
    setCacheValue: (key: string, value: any
    ) => void) => {
    const currentBrowseInputValue = browseInputs[currentBrowseInputIndex].selectedValueId;
    if (currentBrowseInputValue === undefined) return;

    const educationType = browseInputs[EDUCATION_TYPE_INDEX]?.selectedValueId;
    const institutionId = browseInputs[INSTITUTION_INDEX]?.selectedValueId;
    const courseId = browseInputs[COURSE_INDEX]?.selectedValueId;
    const yearId = browseInputs[YEAR_INDEX]?.selectedValueId;
    const moduleId = browseInputs[MODULE_INDEX]?.selectedValueId;

    let endpoint;
    let body;

    switch (currentBrowseInputIndex) {
        case EDUCATION_TYPE_INDEX:
            endpoint = GET_INSTITUTIONS;
            body = { educationType };

            if (educationType === 'university') {
                browseInputs.push({ name: 'University', id: 'institutionId', possibleValues: [] });
            } else {
                browseInputs.push({ name: 'Exam Board', id: 'institutionId', possibleValues: [] });
            }

            break;
        case INSTITUTION_INDEX:
            endpoint = GET_COURSES;
            body = { educationType, institutionId };
            browseInputs.push({ name: 'Course', id: 'courseId', possibleValues: [] });
            break;
        case COURSE_INDEX:
            browseInputs.push({ name: 'Year', id: 'yearId', possibleValues: YEARS(educationType!) });
            break;
        case YEAR_INDEX:
            endpoint = GET_MODULES;
            body = { educationType, institutionId, courseId };
            browseInputs.push({ name: 'Module', id: 'moduleId', possibleValues: [] });
            break;
        case MODULE_INDEX:
            endpoint = GET_QUIZZES;
            body = { educationType, institutionId, courseId, yearId, moduleId };
            browseInputs.push({ name: 'Quiz', id: 'quizId', possibleValues: [] })
            break;
    }

    if (endpoint !== undefined && body !== undefined && currentBrowseInputValue !== undefined) {
        const browseInputValues = await fetchWithLocalCache(endpoint, body, cache, setCacheValue);

        const nextBrowseInputIndex = currentBrowseInputIndex + 1;

        // if the browseInput we have fetched new options for is modules, then filter them by the selected year
        browseInputs[nextBrowseInputIndex].possibleValues = nextBrowseInputIndex === MODULE_INDEX ?
            browseInputValues.filter((newVal: any) => newVal.yearId === yearId) :
            browseInputValues;
    }
}

export const useBrowseManager = (stopIndex?: 0 | 1 | 2 | 3 | 4) => {
    const { cache, setCacheValue } = useContext(CacheContext);

    const [isNextBrowseInputLoading, setIsNextBrowseInputLoading] = useState<boolean>(false);
    const [browseInputs, setBrowseInputs] = useState<BrowseInput[]>([
        { name: 'Level of Education', id: 'educationType', possibleValues: EDUCATION_TYPES }
    ]);

    const onBrowseInputChange = async (index: number, newSelectedValueId?: string) => {
        const newBrowseInputs = [...browseInputs];
        newBrowseInputs[index].selectedValueId = newSelectedValueId;

        if (index === stopIndex) {
            setBrowseInputs(newBrowseInputs);
            return;
        }

        removeFutureBrowseInputs(index, newBrowseInputs);

        setIsNextBrowseInputLoading(true);
        await addNextBrowseInput(index, newBrowseInputs, cache, setCacheValue);
        setIsNextBrowseInputLoading(false);

        setBrowseInputs(newBrowseInputs);
    }

    return { browseInputs, isNextBrowseInputLoading, onBrowseInputChange }
}