import { useContext, useEffect, useState } from 'react';
import { InputFormContainer } from '../../components/InputForm/InputFormContainer';
import { DynamicDropdowns } from './DynamicDropdowns';
import useCustomNavigate from '../../utils/useCustomNavigate';
import { UserContext } from '../../utils/UserContext/UserContext';
import { FileInput } from './FileInput';
import { Button, TextField } from '@mui/material';
import { CREATE_QUIZ, SPACING_SMALL } from '../../constants';
import { COURSE_INDEX, EDUCATION_TYPE_INDEX, INSTITUTION_INDEX, MODULE_INDEX, YEAR_INDEX, useBrowseManager } from '../../utils/useBrowseManager';
import { RandomStringLoader } from '../../components/RandomStringLoader/RandomStringLoader';
import axios from 'axios';
import { PopUpContext } from '../../utils/PopUpContext/PopUpContext';
import { CacheContext } from '../../utils/CacheContext/CacheContext';
import { DisclaimerModal } from '../../components/DisclaimerModal/DisclaimerModal';

export const CreateQuizPage = () => {
    const navigate = useCustomNavigate();
    const { setPopUp } = useContext(PopUpContext);
    const { clearCache } = useContext(CacheContext);
    const { user, subtractQuiz } = useContext(UserContext);

    useEffect(() => {
        if (user === undefined) navigate('/sign-in');
    }, [user, navigate])

    const { browseInputs, isNextBrowseInputLoading, onBrowseInputChange } = useBrowseManager(MODULE_INDEX);

    const [quizTitle, setQuizTitle] = useState<string>();
    const [quizInputText, setQuizInputText] = useState<string>();
    const [createQuizInProgress, setCreateQuizInProgress] = useState<boolean>(false);

    const handleCreateQuiz = async () => {
        const body = {
            educationType: browseInputs[EDUCATION_TYPE_INDEX].selectedValueId,
            institutionId: browseInputs[INSTITUTION_INDEX].selectedValueId,
            courseId: browseInputs[COURSE_INDEX].selectedValueId,
            yearId: browseInputs[YEAR_INDEX].selectedValueId,
            moduleId: browseInputs[MODULE_INDEX].selectedValueId,
            quizInputText,
            quizTitle
        }

        setCreateQuizInProgress(true);

        try {
            const response = await axios.post(CREATE_QUIZ, body, { headers: { authorization: user?.accessToken } });
            setCreateQuizInProgress(false);
            clearCache();
            subtractQuiz();
            if (response?.data?.message) setPopUp({ message: response.data.message, severity: 'success' });
            navigate('/dashboard');
        } catch (error: any) {
            console.error(error);

            if (error.code === 'ERR_NETWORK') {
                setPopUp({ message: 'Your quiz is taking a while to generate. Refresh the dashboard page shortly and it should be complete!', severity: 'success' });
                navigate('/dashboard');
            } else {
                setCreateQuizInProgress(false);
                setPopUp({ message: error?.response?.data?.message ?? 'Unknown error. Please try again later.', severity: 'error' });
            }
        }
    }

    const isUniversity = browseInputs[EDUCATION_TYPE_INDEX]?.selectedValueId === 'university';
    const dropdownsComplete = browseInputs[MODULE_INDEX]?.selectedValueId !== undefined;

    const quizTitleHelperText = isUniversity ?
        "This should be the lecture title, e.g. Newton's 1st Law" :
        "This should be the lesson title, e.g. Newton's 1st Law";

    const quizTitleComplete = quizTitle !== undefined && quizTitle.length > 0;
    const quizInputTextCompleteIfRequired = !isUniversity || (quizInputText !== undefined && quizInputText.length > 0);
    const isFormComplete = dropdownsComplete && quizTitleComplete && quizInputTextCompleteIfRequired;
    const inputFormTitle = createQuizInProgress ? "Creating Quiz" : "Create Quiz";
    const subTitle = 'To keep Quizletic free, all quizzes you generate will be shared with our community. Please provide the following information to make your quiz easily discoverable for others.';

    const disclaimer = `
    You will now have the option to provide the content to generate a quiz from. The content provided
    will be used for quiz creation purposes only and deleted instantly after.\n\n
    By clicking 'I accept' below, you agree that the content you provide is neither copyrighted nor
    the intellectual property of some person, institution, or other entity that is not yourself.\n\n
    You also agree that Quizletic will own all rights to the generated content, though you will be granted a
    revocable license to use the content for personal (non-commercial) purposes.\n\n
    All content generated will be shared publically on the Browse page to be viewed by other users. The content
    can be deleted by Quizletic or yourself, as the creator, at any time from the Dashboard page.\n\n
    If you have any questions regarding this, please contact us via the form in the footer of this page.
    `

    const [disclaimerAccepted, setDisclaimerAccepted] = useState<boolean>(false);

    return (
        <InputFormContainer title={inputFormTitle} subTitle={subTitle}>
            {createQuizInProgress ? (
                <RandomStringLoader />
            ) : (
                <>
                    <DynamicDropdowns {...{ browseInputs, isNextBrowseInputLoading, onBrowseInputChange }} />

                    {(dropdownsComplete && isUniversity) && (
                        <>
                            <DisclaimerModal disclaimer={disclaimer} visible={!disclaimerAccepted} onAccept={() => setDisclaimerAccepted(true)} />
                            <FileInput setQuizInputText={setQuizInputText} setQuizTitle={setQuizTitle} />
                        </>
                    )}

                    {(dropdownsComplete) && (
                        <TextField
                            required
                            fullWidth
                            onChange={(event) => setQuizTitle(event.target.value)}
                            value={quizTitle ?? ''}
                            label="Quiz Title"
                            disabled={isUniversity && quizInputText === undefined}
                            helperText={quizTitleHelperText}
                        />
                    )}

                    <Button
                        fullWidth
                        sx={{ mt: SPACING_SMALL }}
                        variant="contained"
                        color="secondary"
                        disabled={!isFormComplete}
                        onClick={handleCreateQuiz}
                    >
                        Create Quiz
                    </Button>
                </>
            )}
        </InputFormContainer>
    )
}