import Box from '@mui/material/Box';
import logo from '../../assets/logo.svg';
import { LOGO_HEIGHT } from '../../constants';
import useCustomNavigate from '../../utils/useCustomNavigate';
import { SxProps, Theme } from '@mui/material';

interface LogoProps {
	sx?: SxProps<Theme> | undefined
}

export const Logo = ({ sx }: LogoProps) => {
	const navigate = useCustomNavigate();

	return (
		<Box sx={sx}>
			<img src={logo} height={LOGO_HEIGHT} alt="Logo" onClick={() => navigate('/')} style={{ cursor: 'pointer' }} />
		</Box>
	)
}