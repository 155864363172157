import { Box, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { SPACING_EXTRA_SMALL, SPACING_SMALL } from "../../constants";

const RANDOM_MESSAGE_DELAY = 5000;

const LOADING_MESSAGES = [
    "Preparing mind-bending questions...",
    "Loading knowledge nuggets...",
    "Gathering brainiac trivia...",
    "Energizing the quiz generator...",
    "Brain cells are warming up...",
    "Assembling the ultimate quiz experience...",
    "Pouring intelligence into questions...",
    "Loading the quiz matrix...",
    "Igniting synaptic fireworks...",
    "Brewing a storm of quiz brilliance...",
];

const getRandomString = (strings: string[]) => {
    return strings[Math.floor(Math.random() * strings.length)];
};

interface RandomStringLoaderProps {
    strings?: string[]
}

export const RandomStringLoader = ({ strings }: RandomStringLoaderProps) => {
    const [randomString, setRandomString] = useState<string>(getRandomString(strings ?? LOADING_MESSAGES));

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setRandomString(getRandomString(strings ?? LOADING_MESSAGES));
        }, RANDOM_MESSAGE_DELAY);

        return () => clearTimeout(timeoutId);
    }, [randomString, strings]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{ my: SPACING_EXTRA_SMALL, width: "100%" }}
        >
            <CircularProgress />
            <Typography sx={{ marginTop: SPACING_SMALL }}>{randomString}</Typography>
        </Box>
    );
};
