import { red, green, grey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#0B0B45',
            contrastText: '#FFFFFF'
        },
        secondary: {
            main: '#59BFFF',
            contrastText: '#FFFFFF',
            light: '#A4D4FF'
        },
        error: {
            main: red[500],
            contrastText: '#FFFFFF'
        },
        success: {
            main: green.A700,
            contrastText: '#FFFFFF'
        },
        text: {
            primary: '#0B0B45',
        },
        info: {
            main: grey[400],
        }
    },
    typography: {
        fontFamily: [
            '"Segoe UI"',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        button: {
            textTransform: 'none'
        },
        h1: {
            fontSize: '2.5rem',
            fontWeight: '800'
        },
        h2: {
            fontSize: '1.5rem',
            fontWeight: '700'
        },
        h3: {
            fontSize: '1.4rem',
            fontWeight: '600'
        },
        h4: {
            fontSize: '1.2rem',
            fontWeight: '500'
        },
        h5: {
            fontSize: '1.05rem',
            fontWeight: '400'
        }
    }
});

export default theme;

