import { createContext } from 'react';

export interface User {
    accessToken: string,
    id: string
    email: string
    remainingQuizzes: number
};

export interface IUserContext {
    user?: User;
    signIn: (idToken: string, accessToken: string, refreshToken: string) => void;
    signOut: () => void;
    subtractQuiz: () => void;
}

export const UserContext = createContext<IUserContext>({
    user: undefined,
    signIn: (idToken: string, accessToken: string, refreshToken: string) => { },
    signOut: () => { },
    subtractQuiz: () => { }
});