import { Container, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { LandingPageSection } from "./LandingPageSection";
import { DeviceTypeContext } from "../../utils/DeviceTypeContext/DeviceTypeContext";
import SpeedIcon from '@mui/icons-material/Speed';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PeopleIcon from '@mui/icons-material/People';
import ConstructionIcon from '@mui/icons-material/Construction';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { SPACING_EXTRA_SMALL, SPACING_LARGE, SPACING_NORMAL } from "../../constants";

interface Feature {
    title: string
    icon: JSX.Element
    subTitle: string
}

const allFeatures: Feature[] = [
    {
        title: 'Create Quizzes Instantly',
        icon: <AccessTimeIcon sx={{ fontSize: 75, mt: SPACING_EXTRA_SMALL }} color='secondary' />,
        subTitle: "Save time by generating personalized quizzes with AI from lecture notes, web pages, and more."
    },
    {
        title: 'Instant Feedback',
        icon: <SpeedIcon sx={{ fontSize: 75, mt: SPACING_EXTRA_SMALL }} color='secondary' />,
        subTitle: "Quickly fill gaps in your knowledge by reviewing explanations alongside every answer in your quiz."
    },
    {
        title: 'Community Quizzes',
        icon: <PeopleIcon sx={{ fontSize: 75, mt: SPACING_EXTRA_SMALL }} color='secondary' />,
        subTitle: "Quizzes you create are shared with other students so you can reap the benefits of collaborative learning."
    },
    {
        title: 'Coming Soon - Quiz Construction',
        icon: <ConstructionIcon sx={{ fontSize: 75, mt: SPACING_EXTRA_SMALL }} color='secondary' />,
        subTitle: "Hand-pick your favourite questions from your ai-generated quizzes into your own larger quiz."
    },
    {
        title: 'Coming Soon - Fine Tune',
        icon: <PublishedWithChangesIcon sx={{ fontSize: 75, mt: SPACING_EXTRA_SMALL }} color='secondary' />,
        subTitle: "If we generate a question you don't like, regenerate it with a click, yourself, ensuring the best quality quizzes."
    },
    {
        title: 'Coming Soon - Track Performance',
        icon: <TrendingUpIcon sx={{ fontSize: 75, mt: SPACING_EXTRA_SMALL }} color='secondary' />,
        subTitle: "Visualise performance over time against entire quizzes, individual questions, or generic topic areas."
    },
]

export const Features = () => {
    const { deviceType } = useContext(DeviceTypeContext);
    const [benefitsGridColumns, setBenefitsGridColumns] = useState<number>(4);

    useEffect(() => {
        const getBenefitsGridColumns = () => {
            if (deviceType === 'DESKTOP') {
                return 6; // 2 items per row
            } else if (deviceType === 'TABLET') {
                return 6; // 2 items per row
            } else {
                return 12; // 1 item per row
            }
        };

        setBenefitsGridColumns(getBenefitsGridColumns())
    }, [deviceType]);

    return (
        <Container id='features'>
            <LandingPageSection>
                <Typography variant="h1" textAlign="center" sx={{ width: '100%' }}>Our Features</Typography>
                <Typography textAlign="center" sx={{ mb: SPACING_NORMAL, width: '100%' }}>
                    Whether you're a student or teacher, we have a feature set built for you.
                </Typography>

                <Grid container rowSpacing={SPACING_LARGE} spacing={SPACING_LARGE}>
                    {allFeatures.map((feature, index) => (
                        <Grid item xs={benefitsGridColumns} textAlign="center" key={index}>
                            <Typography variant="h3">{feature.title}</Typography>
                            {feature.icon}
                            <Typography>{feature.subTitle}</Typography>
                        </Grid>
                    ))}
                </Grid>
            </LandingPageSection>
        </Container>
    )
};