import { useContext } from 'react';
import { InputForm, InputFormSection } from "../../components/InputForm/InputForm";
import { PopUpContext } from '../../utils/PopUpContext/PopUpContext';
import { CONTACT_US } from '../../constants';

export const ContactUsPage = () => {
	const { setPopUp } = useContext(PopUpContext);

	const handleContactUs = (data: any) => {
		setPopUp({ severity: 'success', message: 'Thanks for your message! We will aim to respond within 48 hours.' })
	}

	const sections: InputFormSection[] = [{
		inputFields: [
			{ label: 'Email', name: 'email', type: 'TEXT' },
			{ label: 'Message', name: 'message', type: 'LARGE_TEXT' }
		]
	}];

	return (
		<InputForm
			title='Contact Us'
			sections={sections}
			submitLabel='Submit Message'
			submitEndpoint={CONTACT_US}
			submitResponseCallback={handleContactUs}
			requireUserToSubmit={false}
		/>
	);
}