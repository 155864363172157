import { Container } from "@mui/material";
import { LandingPageATF } from "./LandingPageATF";
import { LandingPageBTF } from "./LandingPageBTF";

export const LandingPage = () => {
    return (
        <Container disableGutters maxWidth={false}>
            <LandingPageATF />
            <LandingPageBTF />
        </Container>
    );
}
