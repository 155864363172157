import { Box, Container, Typography } from "@mui/material"
import { SPACING_NORMAL } from "../../constants"

export const TermsOfServicePage = () => {

    return (
        <Container maxWidth="md" sx={{ mt: SPACING_NORMAL }}>
            <Typography variant="h1">Terms of Service</Typography>

            <Box sx={{ my: SPACING_NORMAL }}>
                <Typography variant="h2">Introduction</Typography>
                <Typography>
                    These Terms of Service outline the rules and regulations for the use of
                    our services. By accessing this platform, you accept these terms. Do not
                    continue to use this platform if you do not agree with all the terms and
                    conditions stated on this page.
                </Typography>
            </Box>

            <Box sx={{ my: SPACING_NORMAL }}>
                <Typography variant="h2">User Responsibilities</Typography>
                <Typography>
                    As a user of this platform, you agree to conduct yourself in a respectful
                    and lawful manner. You must not engage in any activities that may harm the
                    platform or its users.
                </Typography>
            </Box>

            <Box sx={{ my: SPACING_NORMAL }}>
                <Typography variant="h2">Intellectual Property</Typography>
                <Typography>
                    The core functionality of this platform is to take textual input and generate study
                    materials (e.g. quizzes) from the information provided.

                    The content you provide can be typed manually, or extracted from a document (e.g. PDF).
                    You have the option to edit the content extracted from documents, before sending it to our
                    servers for quiz generation. You agree as a user that you will use this functionality to
                    remove any copyrighted, sensitive, or otherwise protected material.

                    As a user of this site you explicitly agree that the final input you provide is
                    not copyrighted and not protected by Intellectual Property (IP) laws, and does not
                    contain malicious or sensitive information.

                    As a result, the content generated by this platform is protected by
                    intellectual property laws. The platform retains ownership of all
                    content generated but we grant all of our users a non-exclusive, revocable
                    license to use the content for personal purposes (cannot be used commercially).

                    All content generated by a user can be deleted only by that user, or by the platform.
                </Typography>
            </Box>

            <Box sx={{ my: SPACING_NORMAL }}>
                <Typography variant="h2">Disclaimers and Limitation of Liability</Typography>
                <Typography>
                    This platform provides content for informational purposes only. We do not
                    guarantee the accuracy, completeness, or reliability of any content generated.
                    You acknowledge that any reliance on the content is at your own risk. We are
                    not liable for any direct, indirect, incidental, or consequential damages
                    arising out of the use or inability to use our platform.
                </Typography>
            </Box>

            <Box sx={{ my: SPACING_NORMAL }}>
                <Typography variant="h2">Privacy and Data Collection</Typography>
                <Typography>
                    Your privacy is important to us. Please review our Privacy Policy to understand
                    how we collect, use, and disclose information about you. By using our platform,
                    you consent to the practices described in our Privacy Policy.
                </Typography>
            </Box>

            <Box sx={{ my: SPACING_NORMAL }}>
                <Typography variant="h2">Security Measures and Data Retention</Typography>
                <Typography>
                    We implement security measures to protect your data. However, please be aware that
                    no method of transmission over the internet	or electronic storage is 100% secure.
                    We retain user data for as long as necessary to fulfill the purposes outlined in
                    our Privacy Policy.
                </Typography>
            </Box>

            <Box sx={{ my: SPACING_NORMAL }}>
                <Typography variant="h2">Termination</Typography>
                <Typography>
                    We reserve the right to terminate user accounts under certain circumstances, including
                    violation of these Terms. Upon termination,	your access to the platform will be disabled,
                    and any content associated with your account may be deleted, or ownership retained by us.
                    You may contact us for further information regarding account termination.
                </Typography>
            </Box>

            <Box sx={{ my: SPACING_NORMAL }}>
                <Typography variant="h2">Changes to Terms</Typography>
                <Typography>
                    We may update or modify these Terms from time to time. You will be notified of any
                    changes through the platform or via email. Your	continued use of the platform after
                    such modifications will constitute your acknowledgment of the modified Terms and your
                    agreement to abide and be bound by the modified Terms.
                </Typography>
            </Box>

            <Box sx={{ my: SPACING_NORMAL }}>
                <Typography variant="h2">Contact Information</Typography>
                <Typography>
                    If you have any questions or concerns regarding these Terms, please contact us at
                    https://www.quizletic.com/contact-us.
                </Typography>
            </Box>
        </Container>
    )
}
