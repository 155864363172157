import { Container } from "@mui/material";
import { DeviceTypeContext } from "../../utils/DeviceTypeContext/DeviceTypeContext";
import { useContext } from "react";
import { SPACING_EXTRA_LARGE, SPACING_SMALL } from "../../constants";

interface LandingPageSectionProps {
    children: any
}

export const SECTION_PADDING = 10;
export const MOBILE_SECTION_PADDING_TOP = 0;

export const LandingPageSection = ({ children }: LandingPageSectionProps) => {
    const { deviceType } = useContext(DeviceTypeContext);

    const spacing = deviceType === 'MOBILE' ? SPACING_SMALL : SPACING_EXTRA_LARGE;

    return (
        <Container maxWidth="md" sx={{ py: spacing }}>
            {children}
        </Container>
    );
};