import { useContext, useEffect, useState } from "react";
import { PopUpContext } from "../../utils/PopUpContext/PopUpContext";
import { UserContext } from "../../utils/UserContext/UserContext";
import { GET_QUIZ } from "../../constants";
import { Quiz } from "./QuizView";
import { Answer, Question } from "./QuestionView";
import { fetchWithLocalCache } from "../../utils/fetchWithLocalCache";
import { CacheContext } from "../../utils/CacheContext/CacheContext";

export const useFetchQuiz = (quizPartitionKeyValue?: string, quizSortKeyValue?: string, exampleQuiz?: Quiz) => {
    const { user } = useContext(UserContext);
    const { setPopUp } = useContext(PopUpContext);
    const { cache, setCacheValue } = useContext(CacheContext);

    const [quiz, setQuiz] = useState<Quiz>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (exampleQuiz) {
            setQuiz(shuffleQuiz(exampleQuiz));
        } else if (!quizPartitionKeyValue || !quizSortKeyValue) {
            setPopUp({ message: "You cannot view a quiz that doesn't exist!", severity: 'error' });
        } else {
            const fetchQuiz = async () => {
                const config = {
                    headers: {
                        authorization: user?.accessToken,
                    },
                };

                setIsLoading(true);
                try {
                    const response = await fetchWithLocalCache(GET_QUIZ, {
                        quizPartitionKey: quizPartitionKeyValue,
                        quizSortKey: quizSortKeyValue
                    }, cache, setCacheValue, config);

                    setQuiz(shuffleQuiz(response as Quiz));
                    setIsLoading(false);
                } catch (error: any) {
                    setIsLoading(false);
                    setPopUp({ message: error.response.data.message, severity: "error" });
                }
            };

            fetchQuiz();
        }

        return () => {
            setQuiz(undefined);
            setIsLoading(false);
        };

        // Disable missing dependency warning to prevent the quiz being fetched twice (unknown root cause)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { quiz, isLoading };
};

const shuffleArray = (array: any[]) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

const shuffleQuiz = (quiz: Quiz) => {
    const shuffledQuestions: Question[] = shuffleArray(quiz.questions);
    for (let i = 0; i < shuffledQuestions.length; i++) {
        const shuffledAnswers: Answer[] = shuffleArray(shuffledQuestions[i].answers);
        shuffledQuestions[i].answers = shuffledAnswers;
    }

    const shuffledQuiz = JSON.parse(JSON.stringify(quiz));
    shuffledQuiz.questions = shuffledQuestions;
    return shuffledQuiz;
}