import React, { useEffect, useState } from 'react';
import { Cache } from './utils/CacheContext/CacheContext';
import theme from './theme';
import { ThemeProvider } from '@mui/material/styles';
import { CreateQuizPage } from './routes/CreateQuizPage/CreateQuizPage';
import Footer from './components/Footer/Footer';
import { CssBaseline } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { LandingPage } from './routes/LandingPage/LandingPage';
import { SignInPage } from './routes/SignInPage/SignInPage';
import { NavigationBar } from './components/NavigationBar/NavigationBar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { UserContext, User } from './utils/UserContext/UserContext';
import { EmailVerificationPage } from './routes/EmailVerificationPage/EmailVerificationPage';
import { ForgotPasswordPage } from './routes/ForgotPasswordPage/ForgotPasswordPage';
import { ResetPasswordPage } from './routes/ResetPasswordPage/ResetPasswordPage';
import jwtDecode from 'jwt-decode';
import { DashboardPage } from './routes/DashboardPage/DashboardPage';
import { MyAccountPage } from './routes/MyAccountPage/MyAccountPage';
import { PopUp, PopUpContext } from './utils/PopUpContext/PopUpContext';
import { ViewQuizPage } from './routes/ViewQuizPage/ViewQuizPage';
import { DeviceTypeProvider } from './utils/DeviceTypeContext/DeviceTypeContext';
import axios from 'axios';
import { REFRESH_TOKEN } from './constants';
import { TermsOfServicePage } from './routes/TermsOfServicePage/TermsOfServicePage';
import { PrivacyPolicyPage } from './routes/PrivacyPolicyPage/PrivacyPolicyPage';
import { ContactUsPage } from './routes/ContactUsPage/ContactUsPage';
import { SignUpPage } from './routes/SignUpPage/SignUpPage';
import { BrowsePage } from './routes/BrowsePage/BrowsePage';
import { CacheContext } from './utils/CacheContext/CacheContext';

const tryGetUserFromLocalStorage = () => {
    let maybeIdToken = localStorage.getItem('idToken');
    let maybeAccessToken = localStorage.getItem('accessToken');

    if (maybeIdToken && maybeAccessToken) {
        return tryGetUserFromIdToken(maybeIdToken, maybeAccessToken);
    }
}

const tryGetUpdatedUser = async () => {
    let maybeIdToken = localStorage.getItem('idToken');
    let maybeAccessToken = localStorage.getItem('accessToken');
    const maybeRefreshToken = localStorage.getItem('refreshToken');

    // idToken contains the user info (name, email, subscription, etc.) and can
    // quickly become outdated. Refresh tokens allow us to make a call to a lambda
    // to get an updated idToken. This is called every time app mounts, so a page
    // refresh would get a new user object and display updated data.
    if (maybeRefreshToken !== undefined && maybeRefreshToken !== null) {
        try {
            const newTokens = await refreshTokens(maybeRefreshToken);
            maybeIdToken = newTokens.idToken;
            maybeAccessToken = newTokens.accessToken;
            localStorage.setItem('idToken', newTokens.idToken);
            localStorage.setItem('accessToken', newTokens.accessToken);
        } catch (error: any) {
            // do nothing, user will be signed out
        }
    }

    if (maybeIdToken && maybeAccessToken) {
        return tryGetUserFromIdToken(maybeIdToken, maybeAccessToken);
    }
}

const refreshTokens = async (refreshToken: string) => {
    const config = {
        headers: {
            authorization: refreshToken,
        },
    };

    const response = await axios.post(REFRESH_TOKEN, {}, config);

    return { accessToken: response.data.accessToken, idToken: response.data.idToken }
}

const tryGetUserFromIdToken = (idToken: string, accessToken: string) => {
    const user: any = jwtDecode(idToken);

    const currentTimestampSeconds = Math.floor(Date.now() / 1000);
    if (currentTimestampSeconds >= user.exp) return;

    return {
        accessToken: accessToken,
        id: user.sub,
        email: user.email,
        ...getAdditionalUserAttributes(user)
    }
}

const getAdditionalUserAttributes = (user: any) => {
    const maybeRemainingQuizzes: number | undefined = user['custom:remaining_quizzes'];
    return { remainingQuizzes: maybeRemainingQuizzes ?? 0 };
}

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh'
    },
    content: {
        flex: '1 0 auto'
    },
    footer: {
        flexShrink: 0
    },
});

export const App = () => {
    const [user, setUser] = useState<User | undefined>(tryGetUserFromLocalStorage());
    const [popUp, setPopUp] = useState<PopUp>();
    const [cache, setCache] = useState<Cache>({});

    // On load, just get the user from local storage, but on mount try and
    // get an updated user from the refreshToken
    useEffect(() => {
        const getUser = async () => {
            setUser(await tryGetUpdatedUser());
        }

        getUser();
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [popUp])

    const popUpContextValue = {
        setPopUp: setPopUp
    }

    const userContextValue = {
        user: user,
        signIn: (idToken: string, accessToken: string, refreshToken: string) => {
            setUser(tryGetUserFromIdToken(idToken, accessToken));
            localStorage.setItem('idToken', idToken);
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);
        },
        signOut: () => {
            setUser(undefined)
            localStorage.removeItem('idToken');
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
        },
        subtractQuiz: () => {
            const newUser = structuredClone(user);
            if (newUser) {
                newUser.remainingQuizzes--;
                setUser(newUser);
            }
        }
    }

    const cacheContextValue = {
        cache: cache,
        setCacheValue: (key: string, value: any) => {
            const newCache = cache;
            newCache[key] = value;
            setCache(newCache);
        },
        clearCache: () => {
            setCache({});
        }
    }

    const classes = useStyles();

    return (
        <React.StrictMode>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <CacheContext.Provider value={cacheContextValue}>
                    <DeviceTypeProvider>
                        <PopUpContext.Provider value={popUpContextValue}>
                            <UserContext.Provider value={userContextValue}>
                                <div className={classes.container}>
                                    <Router>
                                        <NavigationBar popUp={popUp} />

                                        <div className={classes.content}>
                                            <Routes>
                                                <Route path="/" element={<LandingPage />} />

                                                <Route path="/sign-in" element={<SignInPage />} />
                                                <Route path="/sign-up" element={<SignUpPage />} />
                                                <Route path="/email-verification" element={<EmailVerificationPage />} />
                                                <Route path="/forgot-password" element={<ForgotPasswordPage />} />

                                                <Route path="/reset-password" element={<ResetPasswordPage />} />
                                                <Route path="/browse" element={<BrowsePage />} />
                                                <Route path="/dashboard" element={<DashboardPage />} />
                                                <Route path="/create-quiz" element={<CreateQuizPage />} />
                                                <Route path="/view-quiz" element={<ViewQuizPage />} />
                                                <Route path="/account" element={<MyAccountPage />} />

                                                <Route path="/contact-us" element={<ContactUsPage />} />

                                                <Route path="/terms" element={<TermsOfServicePage />} />
                                                <Route path="/privacy" element={<PrivacyPolicyPage />} />
                                                <Route path="*" element={<LandingPage />} />
                                            </Routes>
                                        </div>

                                        <div className={classes.footer}>
                                            <Footer />
                                        </div>
                                    </Router>
                                </div>
                            </UserContext.Provider>
                        </PopUpContext.Provider>
                    </DeviceTypeProvider>
                </CacheContext.Provider>
            </ThemeProvider>
        </React.StrictMode>
    );
}